type RequestParam = string | number | boolean | object | null

export type RequestParams<T> = {
  [key in keyof T]: RequestParam
}

export default function formatQueryParams(params: Record<string, RequestParam>) {
  // NOTE: broken for JSON-RPC requests that contain lists
  return new URLSearchParams(
    Object.entries(params).reduce(
      (q, [k, v]) => (v !== undefined && v !== null ? { ...q, [k]: v.toString() } : q),
      {}
    )
  ).toString()
}
