import { Address, getAddress } from 'viem'

/**
 * these wallets can be scws or eoas, depending on what is registered with the orderbook
 */
export const MARKET_MAKER_ADDRESSES: Set<Address> = new Set(
  [
    '0x00395DD7E0480a8E38b9237693603994e5112ce9',
    '0x019fDD3a448f72eCa828a35eB35eDfD8C0e86CFB',
    '0x03f4e1646A15b888D9e351EFe34214Cb2D7cBfb1',
    '0x0bd5683e33329C1638BE89fc3B983f1820F982Be',
    '0xF4321cE4F7E813e4334466367f14f127EAf2B925',
    '0x4c7E2cbb3938aB31701416EdCDAefB14b10fBa47',
    '0x2a7D03C5891cB08ddAd1E811131EEf8401099d22',
    '0x2aD91a2ee1a1210077E39E41dE321752c1e9c35a',
    '0xA2CD2504E7B7Cf491D63AdBC587D6A859C8bDa20',
    '0xAA00c7B6F40168Ce785DBa191d1e34F04Dac1BC3',
    '0x3eD4ED6056C8e3549Bb873Ca964C060bB40b8ac0',
    '0x67185284e7eFF005d58816d64D85df6eAC5e0725',
    '0x46dE80db3024f90595DC867c7043cB110A9C75d5',
    '0xf7936fc302849a88E454d0c311C676c7F449821a',
    '0x4c7E2cbb3938aB31701416EdCDAefB14b10fBa47',
    '0x3b8D13d3f89ea04A4b92DC93E67025a11eCD7155',
    '0x55B3d64b865509a9BCE4956F5e21b25E91958dDb',
    '0x600158d017C3874A7Fa9FC7954DBaA3599A3cA42',
    '0x6425f64A067c184E198a4b18e25bB821EAc49282',
    '0x6B0fE721937ed61c6e261b5053D6178eCEf059a4',
    '0x8358D0e1468dD370CdaddAc1Ec1F1b85DfB675bc',
    '0x6dADEFCEde8D12b8E46707a0a6737c6C3688aF26',
    '0x6B0fE721937ed61c6e261b5053D6178eCEf059a4',
    '0x787Cbf841f03cdd5DcDD9E6DC3F2d1F0b6f999dA',
    '0x7B7E1b95ee230a0a2774c14d529236CF4000bf07',
    '0xF98B09e064dc36194618304B6b8d246813aE10d7',
    '0x9E8edbF9da32C33f8D1eD3F1Fc28e9F952FdE66C',
    '0x8358D0e1468dD370CdaddAc1Ec1F1b85DfB675bc',
    '0xC7283eA757954aE0278D7A6DFeD43f2F752123b5',
    '0x8C0066247e6E6421873F2DfEaCd7a9E3BcFaC5a8',
    '0x978e3eb7997a9575cdE1799176829073d2876Fb9',
    '0x019fDD3a448f72eCa828a35eB35eDfD8C0e86CFB',
    '0x9E8edbF9da32C33f8D1eD3F1Fc28e9F952FdE66C',
    '0xE1DC5576b36c78C75e6Abf2E1B121Db20A6069b7',
    '0xA2CD2504E7B7Cf491D63AdBC587D6A859C8bDa20',
    '0xb8382EF2f7A9a0471186fC969d25bb08Ed8Bc91b',
    '0xEAc2374016613765cF31afDB4AEB6f5d13a63726',
    '0xbbbc0fE4408ACb8B9c8c6cC782c93Ba8C791423D',
    '0xbfa7a2CD6ccd7EF8e77AA2181631b2abe61676f5',
    '0x46d8BbD89a592e850E509682b80B85Ede481426B',
    '0xE1c918C6e4bA3efCC9608333b321E0764B4612Dd',
    '0xE1DC5576b36c78C75e6Abf2E1B121Db20A6069b7',
    '0xE2657349db652953F2B05b092c59373200132d02',
    '0x978e3eb7997a9575cdE1799176829073d2876Fb9',
    '0xEAc2374016613765cF31afDB4AEB6f5d13a63726',
    '0xf248E91E8696dd286d492d907f725630E847FBce',
    '0xE2657349db652953F2B05b092c59373200132d02',
    '0xf7936fc302849a88E454d0c311C676c7F449821a',
    '0x88B6BB87fbFac92a34F8155aaA35c87B5b166fA9',
    '0x9cda2eb97A500c16F660F8a79126874c021bbb49',
    '0xCCfAf4103b371Dbc928EB182086c86B759e028Ed',
  ].map((address) => getAddress(address))
) // checksum

/**
 * these wallets can be scws or eoas, depending on what is registered with the orderbook
 */
export const REWARDS_BLOCK_ADDRESSES: Set<Address> = new Set(
  [
    '0x4B508c83F0610f3F5205FBA9b172e33a5A1dbB8b',
    '0x16F59AB002bb71cEa9B1cC82D5c3747671fddAc8',
    '0x2854146d88EEbaA5CCdd8cD094CCa9218B670f89',
    '0xd36Ea14C020e3799f2957Df7fbfE7eCEFA5621D6',
    '0xeddCB87eC326Ba3124923D7075dFD822E9381Ec5',
    '0xD79f148C427cE6c9d704dC0eBf70619cD6784A6D',
    '0xB43E330e62B408e82603A0A34Cec55663BEa5438',
    '0x3537C1a168c9BACE1b10ef54Dd9d911844eD4d46',
    '0x5aeD8F872097863504E498722074304310C48cDB',
    '0x4705355038458585B57472c46AE1Bf534D6D6F28',
    '0xe31605cbEB913f2C3fD026BC74A4A1a1E5006bB9',
    '0x9c2ec4ce082D821e51837F50ded19f7b8bd6d7AC',
    '0xb220B3F090E33cfb62b617e40A55a9f39Ba6786F',
    '0x41B213dA56f091E0872E537f463C31197F67AD8a',
    '0x7d21B965e6E604c04f0885fA140a230C33C8938f',
    '0xb859a91eBC8C63AF5AEc3243B962e1ac8000E47C',
    '0x787Bcc72B91bC7F670ef7D250d5280bB3EF2d0d9',
    '0xA70689e524B23a9503f19067a3ddDB973B40238b',
    '0x429c7FA8531fDa34A3AfF90AD979965f1D7C0B1A',
    '0xcAafE9D035c98A247F403e00893A80A55e09F4FE',
    '0x7d6d307095A4De2a03cDB8326aeb662388Fd0f32',
    '0xB92bFa55709C5F9a1D512E83E9559742D123F7fA',
    '0x8763Bbc7b080dE8d17Cc3D4C5245f3109eE61D35',
    '0x0D83d0825EF241268eDAc49025c683BBfc6736cA',
    '0x9e45bED8bF5592138fBf4385cC4A52f9cdE4fc12',
    '0x73DAF4931b9E41ef2B19a247bF35b9E0119D184b',
    '0xCdef7a2DD184DD3cC4460D37342F4779b2cF4450',
    '0x8c25020926b1617BAA9D964dF6d88D3a3245bfce',
    '0x9e51C62FD127d9d6A0d137B3Af3f193eAE8CdB4d',
    '0xcC51ed8B5bE03748Fec8d92752F1b3C8f5a0cbA7',
    '0x81Aa13194aA8A470C348f02720394e79233D7aDf',
    '0x0F312a4C0E5d67Cd9DFf78e5D99568776dDf1e8a',
  ].map((address) => getAddress(address))
) // checksum

export const EBTC_DEPOSIT_ADDRESS_ALLOWLIST: Set<Address> = new Set(
  [
    '0x9cda2eb97A500c16F660F8a79126874c021bbb49',
    '0x2A5e5e23fdd0868D0c8c78Ddcf2958393bC900EE',
    '0xea21ed29F4339A5B7eEe409974e3E7c86404C85C',
  ].map((address) => getAddress(address))
) // checksum
