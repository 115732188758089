import dateFormat from 'dateformat'

import formatDateTime from './formatDateTime'
import parseDate from './parseDate'

export type FormatDateOptions = {
  skipYear?: boolean
  format?: string
  utc?: boolean
}

export default function formatDate(ts: number | Date, options?: FormatDateOptions): string {
  const skipYear = options?.skipYear ?? false
  const format = options?.format ?? (skipYear ? 'mmm d' : 'mmm d yy')
  return dateFormat(parseDate(ts), format, options?.utc ?? false)
}

export function formatDateTodayOrYesterday(timestamp: number | Date): string {
  const currentDate = new Date()
  let timestampDate: Date
  if (timestamp instanceof Date) {
    timestampDate = timestamp
  } else {
    timestampDate = new Date(timestamp * 1000)
  }

  const isSameDay = (date1: Date, date2: Date): boolean =>
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()

  // Check if it's today
  if (isSameDay(currentDate, timestampDate)) {
    return 'Today'
  }

  // Check if it's yesterday
  const yesterday = new Date()
  yesterday.setDate(currentDate.getDate() - 1)
  if (isSameDay(yesterday, timestampDate)) {
    return 'Yesterday'
  }

  return formatDateTime(timestamp, { hideMins: false, hideYear: true })
}
