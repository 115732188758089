export default [
  {
    inputs: [
      { internalType: 'address', name: 'receiver_', type: 'address' },
      { internalType: 'uint256', name: 'amount_', type: 'uint256' },
      { internalType: 'uint256', name: 'msgGasLimit_', type: 'uint256' },
      { internalType: 'address', name: 'connector_', type: 'address' },
    ],
    name: 'depositToAppChain',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'connector_', type: 'address' }],
    name: 'getCurrentLockLimit',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'connector_', type: 'address' }],
    name: 'getCurrentUnlockLimit',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'connector_', type: 'address' }],
    name: 'getLockLimitParams',
    outputs: [
      {
        components: [
          { internalType: 'uint256', name: 'lastUpdateTimestamp', type: 'uint256' },
          { internalType: 'uint256', name: 'ratePerSecond', type: 'uint256' },
          { internalType: 'uint256', name: 'maxLimit', type: 'uint256' },
          { internalType: 'uint256', name: 'lastUpdateLimit', type: 'uint256' },
        ],
        internalType: 'struct Gauge.LimitParams',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'connector_', type: 'address' },
      { internalType: 'uint256', name: 'msgGasLimit_', type: 'uint256' },
    ],
    name: 'getMinFees',
    outputs: [{ internalType: 'uint256', name: 'totalFees', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'connector_', type: 'address' }],
    name: 'getUnlockLimitParams',
    outputs: [
      {
        components: [
          { internalType: 'uint256', name: 'lastUpdateTimestamp', type: 'uint256' },
          { internalType: 'uint256', name: 'ratePerSecond', type: 'uint256' },
          { internalType: 'uint256', name: 'maxLimit', type: 'uint256' },
          { internalType: 'uint256', name: 'lastUpdateLimit', type: 'uint256' },
        ],
        internalType: 'struct Gauge.LimitParams',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
] as const
