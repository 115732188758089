import { LyraAuthHeaders } from '../constants/api'
import formatQueryParams, { RequestParams } from '../utils/formatQueryParams'
import isServer from '../utils/isServer'
import { RpcError } from './error'

export type ResponseOrError<T> = T | { id: string | number; error: RPCError }

type RPCError = {
  code: number
  message: string
  data?: string
}

export type RequestOptions = {
  cache?: RequestCache
  useGet?: boolean
  noCors?: boolean
}

type RequestOptionsWithAuth = RequestOptions & {
  authHeaders?: LyraAuthHeaders
}

export default async function tryRequest<T, R extends object>(
  endpoint: string,
  data: RequestParams<T>,
  options?: RequestOptionsWithAuth
): Promise<R> {
  // TODO: @earthtojake move API utils out of /core
  const isCorsEnabled =
    !isServer &&
    process.env.NODE_ENV === 'production' &&
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' &&
    !options?.noCors

  const url =
    (isServer || isCorsEnabled ? process.env.NEXT_PUBLIC_ORDERBOOK_API_URL! : '/api/orderbook') +
    endpoint
  const authHeaders = options?.authHeaders ?? {}
  const cache = options?.cache
  const method = !!options?.useGet ? 'GET' : 'POST'
  let response

  if (method === 'GET') {
    const queryParams = formatQueryParams(data)
    response = await fetch(url + '?' + queryParams, {
      method,
      headers: authHeaders,
      cache,
      mode: isCorsEnabled ? 'cors' : undefined,
      credentials: isCorsEnabled ? 'include' : undefined,
    })
  } else {
    response = await fetch(url, {
      // json-rpc is agnostic to method
      // use POST for JSON param packaging
      method,
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        ...authHeaders,
      },
      cache,
      mode: isCorsEnabled ? 'cors' : undefined,
      credentials: isCorsEnabled ? 'include' : undefined,
    })
  }
  if (!response.ok) {
    throw new Error(await response.text())
  }
  const resJson: ResponseOrError<R> = await response.json()

  if ('error' in resJson) {
    throw new RpcError(resJson.error.message, resJson.error.code, resJson.error.data)
  }
  return resJson
}
