// IMPORTANT!!
// converting bigint to number results in loss of precision
// to preserve precision, keep in string format
export function bigNumberToString(number: bigint | string, decimals: number): string {
  const numberString = number.toString()
  const length = numberString.length
  const isNegative = BigInt(number) < 0
  if (length <= decimals) {
    // Pad with zeros if the integer part is shorter than the desired decimals
    const paddedNumberString = '0'.repeat(decimals - length) + numberString.replace(/^-/, '')
    return (
      (isNegative ? '-' : '') + `0.${paddedNumberString.replace(/\.?0+$/, '').replace(/^-/, '')}`
    )
  } else {
    // Insert the decimal point at the appropriate position and trim trailing zeroes
    const decimalPosition = length - decimals
    const resultString = (
      numberString.slice(0, decimalPosition) +
      '.' +
      numberString.slice(decimalPosition)
    ).replace(/\.?0+$/, '')
    return resultString
  }
}
