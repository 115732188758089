import React from 'react'
import { forwardRef } from 'react'
import { FontSizeTokens, SizableText, SizableTextProps, useTheme } from 'tamagui'

import { BodyTextSize, getTextColorVar, TextColor } from '../utils/text'

export type BodyTextProps = {
  size?: BodyTextSize
  color?: TextColor | 'cta'
} & Omit<
  SizableTextProps,
  'fontFamily' | 'fontSize' | 'weight' | 'letterSpacing' | 'size' | 'color'
>

const BodyText = forwardRef<HTMLElement, BodyTextProps>(
  ({ size = 'md', color = 'primary', ...props }, ref) => {
    const sizeToken = ('$' + size) as FontSizeTokens
    const theme = useTheme()

    return (
      <SizableText
        {...props}
        ref={ref}
        fontFamily="$body"
        color={color !== 'cta' ? getTextColorVar(color) : undefined}
        size={sizeToken}
        style={
          color === 'cta'
            ? {
                background: `linear-gradient(${theme.red?.get()}, ${theme.amber?.get()})`,
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }
            : null
        }
      />
    )
  }
)

BodyText.displayName = 'BodyText'

export default BodyText
