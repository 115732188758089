export type ProgressColor = 'red' | 'amber' | 'green' | 'primary' | 'secondary'

export const getProgressColorVar = (color: ProgressColor): string => {
  switch (color) {
    case 'red':
      return '$redText'
    case 'green':
      return '$greenText'
    case 'amber':
      return '$amberText'
    case 'primary':
      return '$primaryText'
    case 'secondary':
      return '$secondaryText'
  }
}
