import { createLightAccount } from '@alchemy/aa-accounts'
import {
  BigNumberish,
  ClientMiddlewareFn,
  createBundlerClient,
  createSmartAccountClientFromExisting,
  defaultFeeEstimator,
  getEntryPoint,
  PromiseOrValue,
  SmartAccountSigner,
  WalletClientSigner,
} from '@alchemy/aa-core'
import formatTruncatedAddress from '@lyra/core/utils/formatTruncatedAddress'
import { BrowserProvider } from 'ethers'
import {
  Address,
  concat,
  custom,
  encodeAbiParameters,
  http,
  isHex,
  toHex,
  Transport,
  WalletClient,
  zeroAddress,
} from 'viem'

import erc20Abi from '../abis/erc20Abi'
import lightAccountFactoryAbi from '../abis/LightAccountFactory'
import { TransactionDisabledReason } from '../constants/auth'
import { DepositNetwork, lyraBundlerUrl, lyraChain, lyraRpcUrl } from '../constants/chains'
import { lyraClient } from '../constants/client'
import { lyraContractAddresses } from '../constants/contracts'
import { isMainnet } from '../constants/env'
import { DepositTokenId, TokenId } from '../constants/tokens'
import {
  DepositNetworkTokenBalances,
  getEmptyDepositBalancesForNetwork,
  getEmptyTokenBalances,
  LyraAccount,
  LyraAccountClient,
  LyraWalletClient,
} from '../constants/wallet'
import { DepositTokenBalances } from '../constants/wallet'
import { getActiveDepositTokens } from './bridge'
import { getNetworkClient } from './rpc'
import { getActiveTokens, getDepositTokenAddress, getLyraTokenAddress } from './tokens'

const DUMB_SIGNATURE =
  '0xfffffffffffffffffffffffffffffff0000000000000000000000000000000000aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaabb1c'

const bundlerRpcMethods = new Set([
  'eth_estimateUserOperationGas',
  'eth_sendUserOperation',
  'eth_getUserOperationByHash',
  'eth_getUserOperationReceipt',
  'eth_supportedEntryPoints',
])

const nodeTransport = http(lyraRpcUrl)
const bundlerTransport = http(lyraBundlerUrl)

const combinedTransport = custom({
  async request({ method, params }) {
    if (bundlerRpcMethods.has(method)) {
      return bundlerTransport({ chain: lyraChain }).request({ method, params })
    } else {
      return nodeTransport({ chain: lyraChain }).request({ method, params })
    }
  },
})

const lyraBundlerClient = createBundlerClient({
  chain: lyraChain,
  transport: combinedTransport,
  cacheTime: 1000,
})

export const lyraEntrypoint = getEntryPoint(lyraChain, {
  version: '0.6.0',
  addressOverride: lyraContractAddresses.accountEntryPoint,
})

const getCustomMiddleware: (account: LyraAccount) => ClientMiddlewareFn = (
  account: LyraAccount
) => {
  if (isMainnet) {
    return (async (uo) => ({
      ...uo,
      nonce: await account.getNonce(),
    })) as ClientMiddlewareFn
  }
  // testnet: need to bump the preVerificationGas
  return (async (uo) => ({
    ...uo,
    nonce: await account.getNonce(),
    preVerificationGas: BigInt((await uo.preVerificationGas)?.toString() ?? 0) * BigInt(2),
  })) as ClientMiddlewareFn
}

const toHexOrString = (input: PromiseOrValue<BigNumberish | undefined> | bigint) => {
  return isHex(input) ? input : toHex(input as bigint)
}

const dummyPaymasterAndData = (): `0x${string}` => {
  const addr = lyraContractAddresses.paymaster
  const validUntil = BigInt(Math.floor(Date.now() / 1000 + 120))
  const validAfter = BigInt(0)
  const erc20 = zeroAddress
  const fee = BigInt(0)
  const encodedPaymasterData = encodeAbiParameters(
    [
      { type: 'uint64', name: 'validUntil' },
      { type: 'uint64', name: 'validAfter' },
      { type: 'address', name: 'erc20' },
      { type: 'uint64', name: 'fee' },
    ],
    [validUntil, validAfter, erc20, fee]
  )

  return concat([addr, encodedPaymasterData, DUMB_SIGNATURE]) as `0x${string}`
}

const paymasterAndData: ClientMiddlewareFn = async (uo) => {
  const res = await fetch('/api/paymaster', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userOp: {
        callData: await uo.callData,
        sender: await uo.sender,
        nonce: toHex((await uo.nonce) as bigint),
        initCode: 'initCode' in uo ? await uo.initCode : undefined,
        callGasLimit: toHexOrString(uo.callGasLimit),
        verificationGasLimit: toHexOrString(uo.verificationGasLimit),
        preVerificationGas: toHexOrString(uo.preVerificationGas),
        maxFeePerGas: toHexOrString(uo.maxFeePerGas),
        maxPriorityFeePerGas: toHexOrString(uo.maxPriorityFeePerGas),
        paymasterAndData: 'paymasterAndData' in uo ? await uo.paymasterAndData : undefined,
        signature: await uo.signature,
      },
    }),
    cache: 'no-store',
  })

  if (!res.ok) {
    throw new Error('failed to fetch paymaster data:' + (await res.text()))
  }

  const { paymasterAndData }: { paymasterAndData: `0x${string}` } = await res.json()

  const newUo = { ...uo, paymasterAndData }

  return newUo
}

const feeEstimator: ClientMiddlewareFn = async (uo, options) => {
  return await defaultFeeEstimator(lyraBundlerClient)(uo, options)
}

export const fetchScwClient = async (client: LyraWalletClient): Promise<LyraAccountClient> => {
  const signer = new WalletClientSigner(client, client.type) as unknown as SmartAccountSigner

  const account = await createLightAccount<Transport, SmartAccountSigner, 'v1.1.0', '0.6.0'>({
    transport: combinedTransport,
    chain: lyraChain,
    signer,
    entryPoint: lyraEntrypoint,
    factoryAddress: lyraContractAddresses.accountFactory,
    version: 'v1.1.0',
  })

  return createSmartAccountClientFromExisting({
    client: lyraBundlerClient,
    account,
    paymasterAndData: {
      dummyPaymasterAndData,
      paymasterAndData,
    },
    customMiddleware: getCustomMiddleware(account),
    feeEstimator,
  }) as unknown as LyraAccountClient
}

export const fetchScwAddress = async (ownerAddress: Address) => {
  const address = await lyraClient.readContract({
    abi: lightAccountFactoryAbi,
    address: lyraContractAddresses.accountFactory,
    functionName: 'getAddress',
    args: [ownerAddress, 0],
  })
  return address as Address
}

export const fetchTokenBalances = async (address: Address): Promise<Record<TokenId, bigint>> => {
  const tokens = getActiveTokens()

  // @ts-ignore infinite depth call, typescript wigging out
  const balances = await lyraClient.multicall({
    contracts: tokens.map((tokenId) => ({
      abi: erc20Abi,
      address: getLyraTokenAddress(tokenId),
      functionName: 'balanceOf',
      args: [address],
    })),
  })

  return balances.reduce((balances, balance, i) => {
    const token = tokens[i]
    if (balance.status === 'failure') {
      console.warn('[multicall] failed to fetch balanceOf for', token)
      return balances
    }
    return {
      ...balances,
      [token]: balance.result,
    }
  }, getEmptyTokenBalances())
}

const fetchDepositTokenBalancesForNetwork = async (
  network: DepositNetwork,
  ownerAddress: Address
): Promise<DepositNetworkTokenBalances> => {
  // Note: need to fetch ETH balance with separate call
  const depositTokens = getActiveDepositTokens(network).filter(
    (token) => token !== DepositTokenId.ETH
  )
  const client = await getNetworkClient(network)

  // @ts-ignore infinite depth call, typescript wigging out
  const [balances, _ethBalance] = await Promise.all([
    client.multicall({
      contracts: depositTokens.map((token) => ({
        abi: erc20Abi,
        address: getDepositTokenAddress(network, token),
        functionName: 'balanceOf',
        args: [ownerAddress],
      })),
    }),
    client.getBalance({ address: ownerAddress }),
  ])

  const ethBalance = _ethBalance

  const depositBalances = balances.reduce((balances, balance, i) => {
    const depositToken = depositTokens[i]
    if (balance.status === 'failure') {
      console.warn('failed to fetch deposit balance for', depositToken)
      return balances
    }
    return {
      ...balances,
      [depositToken]: balance.result,
    }
  }, getEmptyDepositBalancesForNetwork())

  depositBalances[DepositTokenId.ETH] = ethBalance

  return depositBalances
}

export const fetchDepositTokenBalances = async (
  ownerAddress: Address
): Promise<DepositTokenBalances> => {
  const networks = Object.values(DepositNetwork)
  const networkBalances = await Promise.all(
    networks.map((network) => fetchDepositTokenBalancesForNetwork(network, ownerAddress))
  )
  return networkBalances.reduce(
    (dict, balances, idx) => ({
      ...dict,
      [networks[idx]]: balances,
    }),
    {} as DepositTokenBalances
  )
}

export function walletClientToSigner(walletClient: WalletClient) {
  const { chain, transport } = walletClient
  if (!chain) {
    throw new Error('walletClientToSigner chain is not defined')
  }
  const network = {
    chainId: chain.id,
    name: chain.name,
  }
  return new BrowserProvider(transport, network)
}

export const getDepositTokenBalance = (
  balances: DepositTokenBalances,
  network: DepositNetwork,
  token: DepositTokenId
): bigint => {
  const networkBalances = balances[network]
  return networkBalances[token] ?? BigInt(0)
}

export const getTransactionDisabledMessage = (
  disabledReason: TransactionDisabledReason,
  targetOwnerAddress?: Address
): string => {
  switch (disabledReason) {
    case 'geoblocked':
    case 'ofac-restricted':
      return 'Derive is not available to residents, citizens or companies incorporated in the United States of America, Australia and other restricted regions.'
    case 'kyt':
      return 'Your wallet address has been associated with risk by Chainalysis'
    case 'kyt-failed':
      return 'Something went wrong. Please contact support.'
    case 'terms':
      return 'To use Derive, you need to acknowledge the Terms of Use and Privacy Policy'
    case 'invalid-wallet':
      return 'Your account is in an invalid state. Please contact support.'
    case 'eoa-reconnect':
      return `Select the wallet ${
        targetOwnerAddress
          ? `with address ${formatTruncatedAddress(targetOwnerAddress)}`
          : 'you signed in with'
      } and follow the instructions to reconnect your wallet to Derive`
  }
}
