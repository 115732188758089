import { SECONDS_IN_HOUR } from '@lyra/core/constants/time'
import jwt, { SignOptions } from 'jsonwebtoken'

const ALCHEMY_KEY_ID = process.env.ALCHEMY_KEY_ID!
const ALCHEMY_JWT_PRIVATE_KEY = process.env.ALCHEMY_JWT_PRIVATE_KEY!

const ALCHEMY_JW_DURATION = SECONDS_IN_HOUR // 1 hour

export const generateRpcTokenSERVER = async () => {
  const signOptions: SignOptions = {
    expiresIn: ALCHEMY_JW_DURATION,
    header: {
      kid: ALCHEMY_KEY_ID,
      alg: 'RS256',
    },
  }

  // Sign an empty payload using the private key and the sign options (empty payload because we are not sending any fields in the JWT)
  const token = jwt.sign({}, ALCHEMY_JWT_PRIVATE_KEY, signOptions)

  return { token, maxAgeSecs: ALCHEMY_JW_DURATION }
}
