import TestnetAddresses from './prod_lyra-testnet_addresses.json'
import {
  SocketDepositContractAddresses,
  SocketWithdrawContractAddresses,
  TESTNET_CHAIN_ID,
} from './shared'

// Alternatively could explicitly list the chainIds to avoid casting as never/SocketDepositContractAddresses

const depositContractAddresses: SocketDepositContractAddresses = {}

for (const chainId of Object.keys(TestnetAddresses)) {
  const addrs = (TestnetAddresses as any)[chainId]

  for (const tokenId of Object.keys(addrs)) {
    addrs[tokenId].isNewBridge = Object.keys(addrs[tokenId]).some((x) => x.includes('Hook'))
  }

  depositContractAddresses[chainId] = {
    ...addrs,
  }
}

const withdrawContractAddresses: SocketWithdrawContractAddresses = TestnetAddresses[
  TESTNET_CHAIN_ID
] as any

for (const tokenId of Object.keys(withdrawContractAddresses)) {
  withdrawContractAddresses[tokenId].isNewBridge = Object.keys(
    withdrawContractAddresses[tokenId]
  ).some((x) => x.includes('Hook'))
}

export const TESTNET_DEPOSIT_CONTRACT_ADDRESSES = depositContractAddresses
export const TESTNET_WITHDRAW_CONTRACT_ADDRESSES = withdrawContractAddresses
