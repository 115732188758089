import { isTestnet } from './env'

export const MOCK_SESSION_COOKIE_NAME = 'derive-mock-session-did'
export const DEPLOY_COOKIE_NAME = 'derive-deploy'
export const ALCHEMY_RPC_COOKIE_NAME = 'derive-alchemy-rpc-token'
export const ADMIN_COOKIE_NAME = 'derive-admin'
export const INVITE_CODE_COOKIE_NAME = isTestnet
  ? 'derive-testnet-invite-code'
  : 'derive-mainnet-invite-code'
export const PRIVY_TOKEN_COOKIE_NAME = 'privy-token'
export const PRIVY_SESSION_COOKIE_NAME = 'privy-session'
export const PRIVY_ID_TOKEN_COOKIE_NAME = 'privy-id-token'
