import { BatchUserOperationCallData } from '@alchemy/aa-core'
import { TaskState } from '@gelatonetwork/relay-sdk/dist/lib/status/types'
import { MAX_INT } from '@lyra/core/constants/contracts'
import filterNulls from '@lyra/core/utils/filterNulls'
import formatQueryParams from '@lyra/core/utils/formatQueryParams'
import vaultAbi from '@lyra/web/abis/vaultAbi'
import { Address, encodeFunctionData, Hash, toHex } from 'viem'

import DepositHelperAbi from '../abis/DepositHelperAbi'
import erc20Abi from '../abis/erc20Abi'
import vaultOldAbi from '../abis/vaultOldAbi'
import withdrawalHelperAbi from '../abis/withdrawHelperAbi'
import { KytError } from '../constants/auth'
import {
  BRIDGE_FAILED_STATUSES,
  BridgeContractAddresses,
  BridgeOptions,
  BridgeTransaction,
  BridgeTransactionStatus,
  BridgeWithdrawContractAddresses,
  BridgeWithdrawContractHookAddresses,
  bridgeWithdrawHelperAddress,
  externalDepositContractAddresses,
  SOCKET_BRIDGE_L2_GAS_LIMIT,
  SOCKET_MESSAGE_API_URL,
  SOCKET_MESSAGE_COMPLETE_STATUSES,
  SOCKET_MESSAGE_STATUSES,
  SOCKET_TX_API_URL,
  socketDepositContractAddresses,
  SocketMessageIdStatusResponse,
  SocketMessageStatus,
  SocketStatusResponse,
  socketWithdrawContractAddresses,
} from '../constants/bridge'
import { DepositNetwork } from '../constants/chains'
import { lyraClient } from '../constants/client'
import { isMainnet } from '../constants/env'
import { depositTokenConfig, DepositTokenId, ETH_ADDRESS, TokenId } from '../constants/tokens'
import { TransactionOptions } from '../constants/transactions'
import { HELP_BRIDGE_URL, HELP_ENABLE_SPENDING_URL } from '../constants/urls'
import { User } from '../constants/user'
import { LyraWalletClient } from '../constants/wallet'
import { DepositQuote } from '../hooks/useDepositQuote'
import { getChainForDepositNetwork } from './chains'
import { getNetworkClient } from './rpc'
import {
  formatDepositTokenBalance,
  formatDepositTokenSymbol,
  formatSocketDepositTokenSymbol,
  getDepositTokenAddress,
  getLyraTokenAddress,
} from './tokens'
import { coerce } from './types'
import { fetchScwAddress, getTransactionDisabledMessage } from './wallet'

export type BridgeResponse = {
  isNative: boolean
  isSponsored: boolean
  txHash: Hash
  amount: bigint
  fees: bigint
}

export const bridgeToLyraChain = async (
  walletClient: LyraWalletClient,
  network: DepositNetwork,
  token: DepositTokenId,
  amount: bigint,
  bridgeOptions: BridgeOptions,
  options: TransactionOptions
): Promise<BridgeResponse> => {
  const chain = getChainForDepositNetwork(network)

  if (chain.id !== walletClient.chain.id) {
    throw new Error(`Not connected to ${network} network`)
  }

  return await nativeBridgeToLyraChain(
    walletClient,
    network,
    token,
    amount,
    bridgeOptions.isMaxApproval,
    options
  )
}

const nativeBridgeToLyraChain = async (
  walletClient: LyraWalletClient,
  network: DepositNetwork,
  token: DepositTokenId,
  amount: bigint,
  isMaxApproval: boolean,
  options: TransactionOptions
): Promise<BridgeResponse> => {
  const chain = getChainForDepositNetwork(network)

  const ownerAddress = walletClient.account.address

  const { socketVault, socketDepositFastConnector, nativeHelper, isNewBridge } =
    getDepositContractAddresses(network, token)

  if (!nativeHelper) {
    throw new Error()
  }

  // Deposit via socket vault directly if:
  // - is new Socket connector OR
  // - depositing USDT (requires special approval flow which have bricked/will brick the helper contracts)
  const toApprove = isNewBridge || token === DepositTokenId.USDT ? socketVault : nativeHelper

  const networkClient = await getNetworkClient(network)
  const tokenAddress = getDepositTokenAddress(network, token)

  if (isMainnet) {
    // !!IMPORTANT
    // Screen native deposits BEFORE transaction is executed
    console.debug('screening wallet')
    const screenRes = await fetch('/api/deposit/screen', {
      method: 'POST',
      body: JSON.stringify({
        amount: toHex(amount),
        network,
        token,
      }),
    })

    if (!screenRes.ok) {
      if (screenRes.status === 500) {
        throw new Error('Wallet screening failed. Please try again.')
      } else if (screenRes.status === 403) {
        throw new KytError(getTransactionDisabledMessage('kyt'))
      } else {
        throw new Error('Deposit screening failed')
      }
    }
  }
  const [allowance, socketEthFee] = await Promise.all([
    // Note: no allowance for native ETH deposits
    token !== DepositTokenId.ETH
      ? networkClient.readContract({
          abi: erc20Abi,
          address: tokenAddress,
          functionName: 'allowance',
          args: [ownerAddress, toApprove],
        })
      : undefined,
    fetchSocketBridgeFee(network, token),
  ])

  // Note: reset approval for USDT (unique requirement of USDT)
  if (token === DepositTokenId.USDT && allowance && allowance < amount) {
    options.onTransactionStatusChange('confirm', {
      title: `Enable Spending ${formatDepositTokenSymbol(token)} on Derive`,
      contextLink: {
        href: HELP_ENABLE_SPENDING_URL,
        label: 'Why is this required?',
        target: '_blank',
      },
    })
    const approveHash = await walletClient.writeContract({
      address: tokenAddress,
      abi: erc20Abi,
      functionName: 'approve',
      args: [toApprove, BigInt(0)],
    })
    const approveReceipt = await networkClient.waitForTransactionReceipt({ hash: approveHash })
    if (approveReceipt.status === 'reverted') {
      throw new Error('Approve transaction reverted')
    }
  }

  if (allowance !== undefined && allowance < amount) {
    console.debug('insufficient allowance', { allowance, amount })

    options.onTransactionStatusChange('confirm', {
      title: `Enable Spending ${formatDepositTokenSymbol(token)} on Derive`,
      contextLink: {
        href: HELP_ENABLE_SPENDING_URL,
        label: 'Why is this required?',
        target: '_blank',
      },
    })

    // Note: use permit signature for assets that support permit
    const approveHash = await walletClient.writeContract({
      address: tokenAddress,
      abi: erc20Abi,
      functionName: 'approve',
      args: [toApprove, isMaxApproval ? MAX_INT : amount],
    })

    options.onTransactionStatusChange('in-progress', {
      title: `Enabling Spending ${formatDepositTokenSymbol(token)} on Derive`,
    })

    const approveReceipt = await networkClient.waitForTransactionReceipt({ hash: approveHash })
    if (approveReceipt.status === 'reverted') {
      throw new Error('Approve transaction reverted')
    }

    console.debug('approval receipt', approveReceipt)
  }

  options.onTransactionStatusChange('confirm', {
    title: `Confirm Deposit ${formatDepositTokenBalance(amount, token)}`,
    context: `Deposits typically take ${getBridgeDurationEstimate(network)}.`,
    contextLearnMoreHref: HELP_BRIDGE_URL,
  })

  let txHash: Hash

  const gasLimit = BigInt(SOCKET_BRIDGE_L2_GAS_LIMIT)

  if (token === DepositTokenId.ETH) {
    // native token, wrap into wETH then deposit
    console.debug('submitting native ETH deposit', {
      socketVault,
      gasLimit,
      socketDepositFastConnector,
      value: amount + socketEthFee,
    })
    txHash = await walletClient.writeContract({
      address: nativeHelper,
      abi: DepositHelperAbi,
      functionName: 'depositETHToLyra',
      args: [socketVault, true /* isSCW */, gasLimit, socketDepositFastConnector],
      value: amount + socketEthFee, // ETH value to be transferred
    })
  } else {
    console.debug('submitting native deposit', {
      tokenAddress,
      socketVault,
      amount,
      gasLimit,
      socketDepositFastConnector,
      value: socketEthFee,
    })
    if (isNewBridge) {
      // SUPER IMPORTANT!! must be smart contract wallet address
      const receiver = await fetchScwAddress(ownerAddress)
      txHash = await walletClient.writeContract({
        address: socketVault,
        abi: vaultAbi,
        functionName: 'bridge',
        args: [receiver, amount, gasLimit, socketDepositFastConnector, '0x', '0x'],
        value: socketEthFee,
      })
    } else if (token === DepositTokenId.USDT) {
      // SUPER IMPORTANT!! must be smart contract wallet address
      const receiver = await fetchScwAddress(ownerAddress)
      txHash = await walletClient.writeContract({
        address: socketVault,
        abi: vaultOldAbi,
        functionName: 'depositToAppChain',
        args: [receiver, amount, gasLimit, socketDepositFastConnector],
        value: socketEthFee,
      })
    } else {
      txHash = await walletClient.writeContract({
        address: nativeHelper,
        abi: DepositHelperAbi,
        functionName: 'depositToLyra',
        args: [
          tokenAddress,
          socketVault,
          true /* isSCW */,
          amount,
          gasLimit,
          socketDepositFastConnector,
        ],
        value: socketEthFee,
      })
    }
  }

  options.onTransactionStatusChange('in-progress', {
    txHash,
    chain,
    title: `Depositing ${formatDepositTokenBalance(amount, token)}`,
  })

  // Note: only wait for tx receipt for ETH deposits to subtract fee
  // Avoids risk of user closing modal and subaccount deposit not being queued
  const receipt = await networkClient.waitForTransactionReceipt({ hash: txHash })
  if (receipt.status === 'reverted') {
    throw new Error('Transaction reverted')
  }

  // socket fee
  const fees = socketEthFee

  const apiParams = {
    amount: toHex(amount),
    network,
    token,
    transactionHash: txHash,
  }

  const depositRes = await fetch('/api/deposit', {
    method: 'POST',
    body: JSON.stringify(apiParams),
  })

  if (!depositRes.ok) {
    throw new Error(
      'Deposit was successful but Derive failed to log your transaction. Please contact support.'
    )
  }

  if (!options.skipCompleteStatus) {
    options.onTransactionStatusChange('complete', {
      txHash,
      chain,
      title: `Successfully Deposited ${formatDepositTokenBalance(amount, token)}`,
      context: `Your ${formatDepositTokenSymbol(
        token
      )} will be available in your Funding Account in ${getBridgeDurationEstimate(network)}.`,
    })
  }

  return { isNative: true, isSponsored: false, txHash, amount, fees }
}

// returns all active collaterals for a network
export const getActiveDepositTokens = (network: DepositNetwork) => {
  const chainId = getChainForDepositNetwork(network).id.toString()
  const networkSocketAddresses = socketDepositContractAddresses[chainId]
  if (!networkSocketAddresses) {
    return []
  }
  const depositTokens = filterNulls(
    Object.keys(networkSocketAddresses).map((token) => coerce(DepositTokenId, token))
  )

  depositTokens.push(DepositTokenId.ETH)

  return depositTokens.filter((token) => depositTokenConfig[token].isActive)
}

// returns all active collaterals for a network with additional filtering
export const getSupportedDepositTokens = (
  network: DepositNetwork,
  user?: User
): DepositTokenId[] => {
  const depositTokens = getActiveDepositTokens(network)

  return depositTokens.filter((tokenId) => {
    const allowlist = depositTokenConfig[tokenId].allowlist
    return !allowlist || (user && (allowlist.has(user.ownerAddress) || allowlist.has(user.address)))
  })
}

export const getActiveWithdrawTokens = (network: DepositNetwork): DepositTokenId[] => {
  const withdrawTokens = Object.keys(socketWithdrawContractAddresses).filter((token) => {
    const chainId = getChainForDepositNetwork(network).id.toString()
    return !!socketWithdrawContractAddresses[token as DepositTokenId]?.connectors[chainId]?.FAST
  }) as DepositTokenId[]

  return filterNulls(withdrawTokens.map((token) => coerce(DepositTokenId, token))).filter(
    (token) => depositTokenConfig[token].isActive
  )
}

export const getIsBridgeTxPending = (tx: BridgeTransaction) => {
  const bridgeType =
    tx.type === 'deposit' || tx.type === 'withdraw' ? tx.bridgeType : tx.fromStatus.bridgeType

  if (bridgeType === 'layer-zero') {
    // TODO @michaelxuwu
    return {
      isPending: false,
    }
  }
  const bridgeStatus = (
    tx.type === 'deposit' || tx.type === 'withdraw' ? tx.status : tx.fromStatus.status
  ) as SocketMessageStatus
  const isSocketPending =
    // Check status is a Socket status for migration from old combined status
    SOCKET_MESSAGE_STATUSES.includes(bridgeStatus) &&
    !SOCKET_MESSAGE_COMPLETE_STATUSES.includes(bridgeStatus)
  const hasBridgeFailed = BRIDGE_FAILED_STATUSES.includes(bridgeStatus)
  return {
    isPending: !hasBridgeFailed && isSocketPending,
    isSocketPending,
  }
}

export const getOutboundTxHash = (tx: BridgeTransaction) =>
  tx.type === 'deposit' || tx.type === 'withdraw' ? tx.srcTxHash : tx.toStatus.srcTxHash

export const getTxBridgeStatus = (
  tx: BridgeTransaction
): { status: BridgeTransactionStatus; message?: string } => {
  const { isPending, isSocketPending } = getIsBridgeTxPending(tx)
  // Pending
  if (isPending) {
    return {
      status: 'pending',
      message: isSocketPending ? 'Bridge transaction is in progress' : undefined,
    }
  }

  // Gelato failure
  if (tx.type === 'deposit' && tx.status === TaskState.Cancelled) {
    return {
      status: 'cancelled',
      message: 'Transaction cancelled by relayer. No fees have been charged',
    }
  } else if (tx.type === 'deposit' && tx.status === 'ExecReverted') {
    return {
      status: 'reverted',
      message: 'Transaction reverted on-chain',
    }
  }

  // Bridge failure
  const bridgeStatus =
    tx.type === 'deposit' || tx.type === 'withdraw' ? tx.status : tx.fromStatus.status
  if (bridgeStatus === 'EXECUTION_FAILURE' || bridgeStatus === 'INBOUND_REVERTING') {
    return { status: 'reverted', message: 'Transaction reverted on-chain' }
  }

  return { status: 'completed' }
}

export const getDepositContractAddresses = (
  network: DepositNetwork,
  token: DepositTokenId
): BridgeContractAddresses => {
  const chain = getChainForDepositNetwork(network)
  // WETH and ETH share the same configs
  const socketToken = formatSocketDepositTokenSymbol(token)

  // These must be defined for bridge
  const socketAddresses = socketDepositContractAddresses[chain.id.toString()][socketToken]
  const externalAddresses = externalDepositContractAddresses[network]

  if (!socketAddresses || !externalAddresses) {
    throw new Error(`Bridge not supported for ${token} on ${network} network`)
  }

  const socketConnectors =
    '957' in socketAddresses.connectors
      ? // Mainnet
        socketAddresses.connectors['957']
      : // Testnet
        socketAddresses.connectors['901']

  return {
    ...externalAddresses,
    isNewBridge: socketAddresses.isNewBridge ?? false,
    tokenAddress: token === DepositTokenId.ETH ? ETH_ADDRESS : socketAddresses.NonMintableToken,
    socketDepositFastConnector: socketConnectors.FAST,
    socketVault: socketAddresses.Vault,
  }
}

export const getWithdrawContractAddresses = (
  network: DepositNetwork,
  token: DepositTokenId
): BridgeWithdrawContractAddresses => {
  const socketToken = formatDepositTokenSymbol(token)
  const socketAddresses = socketWithdrawContractAddresses[socketToken]
  const chainId = getChainForDepositNetwork(network).id.toString()
  if (!socketAddresses) {
    throw new Error(`Bridge not supported for ${token} on Derive`)
  }
  const networkConnectors = socketAddresses.connectors[chainId]
  const withdrawHookAddresses: BridgeWithdrawContractHookAddresses = socketAddresses.isNewBridge
    ? {
        isNewBridge: true,
        shareHandlerWithdrawHook: socketAddresses.LyraTSAShareHandlerDepositHook,
      }
    : { isNewBridge: false, shareHandlerWithdrawHook: undefined }

  return {
    // Address for controller on Lyra Chain
    // Note: this determines destination chain and destination token for withdraws
    // e.g. for USDC vs USDC.e
    lyraTokenAddress: socketAddresses.MintableToken,
    socketController: socketAddresses.Controller,
    socketWithdrawFastConnector: networkConnectors.FAST,
    withdrawHelper: bridgeWithdrawHelperAddress,
    ...withdrawHookAddresses,
  }
}

export const fetchBridgeFromLyraChainTxs = async ({
  // !!SUPER IMPORTANT!!
  // sender is always smart contract wallet address
  // receiver is typically owner address
  // FAILURE TO SET THE RECEIVER ADDRESS CORRECTLY WILL RESULT IN LOSS OF FUNDS
  receiver,
  withdrawNetwork,
  token,
  withdrawToken,
  amount,
}: {
  receiver: Address
  withdrawNetwork: DepositNetwork
  token: TokenId
  withdrawToken: DepositTokenId
  amount: bigint
}): Promise<BatchUserOperationCallData> => {
  const { socketController, socketWithdrawFastConnector, withdrawHelper } =
    getWithdrawContractAddresses(withdrawNetwork, withdrawToken)

  if (!socketWithdrawFastConnector) {
    throw new Error(`Withdraws not supported for ${withdrawToken} to ${withdrawNetwork}`)
  }

  const txs: BatchUserOperationCallData = []
  const withdrawalGasLimit = await fetchBridgeWithdrawGasLimit(receiver, withdrawNetwork)

  const tokenAddress = getLyraTokenAddress(token)

  console.debug('approving for withdrawal')
  txs.push({
    target: tokenAddress,
    data: encodeFunctionData({
      abi: erc20Abi,
      functionName: 'approve',
      args: [withdrawHelper, MAX_INT],
    }),
  })

  console.debug('withdraw')
  txs.push({
    target: withdrawHelper,
    data: encodeFunctionData({
      abi: withdrawalHelperAbi,
      functionName: 'withdrawToChain',
      args: [
        tokenAddress,
        amount,
        // !!SUPER IMPORTANT!!
        // receiver is always owner address on destination chain
        // FAILURE TO SET THE RECEIVER ADDRESS CORRECTLY WILL RESULT IN LOSS OF FUNDS
        receiver,
        socketController,
        socketWithdrawFastConnector,
        withdrawalGasLimit,
      ],
    }),
  })

  return txs
}

export const getBridgeDurationEstimate = (network: DepositNetwork): string => {
  switch (network) {
    case DepositNetwork.Arbitrum:
    case DepositNetwork.Optimism:
    case DepositNetwork.Base:
      return '1-2 minutes'
    case DepositNetwork.Ethereum:
      return '5-10 minutes'
  }
}

const fetchBridgeWithdrawGasLimit = async (
  ownerAddress: Address,
  withdrawNetwork: DepositNetwork
): Promise<bigint> => {
  const networkClient = await getNetworkClient(withdrawNetwork)
  switch (withdrawNetwork) {
    case DepositNetwork.Arbitrum: {
      // Note: arbitrum gas depends on L1 gas prices, must be calculated relative to erc20 transfer
      // gas for erc20 transfer
      const estGasUsed = await networkClient.estimateGas({ account: ownerAddress })
      return BigInt(5) * estGasUsed
    }
    case DepositNetwork.Ethereum:
    case DepositNetwork.Optimism:
    case DepositNetwork.Base:
      return BigInt(SOCKET_BRIDGE_L2_GAS_LIMIT)
  }
}

export const fetchWithdrawFee = async (
  ownerAddress: Address,
  network: DepositNetwork,
  token: TokenId,
  withdrawToken: DepositTokenId
): Promise<bigint> => {
  const { withdrawHelper, socketController, socketWithdrawFastConnector } =
    getWithdrawContractAddresses(network, withdrawToken)

  if (!socketWithdrawFastConnector) {
    throw new Error(`Withdraws not supported for ${withdrawToken} to ${network}`)
  }

  const gasLimit = await fetchBridgeWithdrawGasLimit(ownerAddress, network)
  const tokenAddress = getLyraTokenAddress(token)
  return await lyraClient.readContract({
    address: withdrawHelper,
    abi: withdrawalHelperAbi,
    functionName: 'getFeeInToken',
    args: [tokenAddress, socketController, socketWithdrawFastConnector, gasLimit],
  })
}

const fetchBridgeDepositGasLimit = async (
  ownerAddress: Address,
  network: DepositNetwork,
  isNative: boolean
): Promise<bigint> => {
  const networkClient = await getNetworkClient(network)
  switch (network) {
    case DepositNetwork.Base:
    case DepositNetwork.Arbitrum: {
      // Note: arbitrum gas depends on L1 gas prices, must be calculated relative to erc20 transfer
      // gas for erc20 transfer
      const estGasUsed = await networkClient.estimateGas({ account: ownerAddress })
      // Gelato: 5.3x erc20 transfer
      // Native: 4.5x erc20 transfer
      return (BigInt(isNative ? 45 : 53) / BigInt(10)) * estGasUsed
    }
    case DepositNetwork.Ethereum:
      // 250K gas cost + Gelato 150K overhead.
      return BigInt(isNative ? 250_000 : 400_000)
    case DepositNetwork.Optimism:
      if (!isNative) {
        throw new Error('Gelato deposits not supported on Optimism network')
      }
      // 180k gas cost
      return BigInt(180_000)
  }
}

// Fetches Socket L2 gas fee for minting tokens on Lyra Chain
// Used for total fee estimation
export const fetchSocketBridgeFee = async (
  network: DepositNetwork,
  token: DepositTokenId
): Promise<bigint> => {
  const networkClient = await getNetworkClient(network)

  const {
    socketVault,
    socketDepositFastConnector,
    isNewBridge: isNew,
  } = getDepositContractAddresses(network, token)
  const gasLimit = BigInt(SOCKET_BRIDGE_L2_GAS_LIMIT)

  if (isNew) {
    return networkClient.readContract({
      abi: vaultAbi,
      address: socketVault,
      functionName: 'getMinFees',
      args: [socketDepositFastConnector, gasLimit, BigInt(161)],
    })
  } else {
    return networkClient.readContract({
      abi: vaultOldAbi,
      address: socketVault,
      functionName: 'getMinFees',
      args: [socketDepositFastConnector, gasLimit],
    })
  }
}

export const fetchEstimateNativeBridgeGasFee = async (
  ownerAddress: Address,
  network: DepositNetwork
): Promise<bigint> => {
  const networkClient = await getNetworkClient(network)
  const [feePerGas, gasLimit] = await Promise.all([
    networkClient.estimateFeesPerGas({
      // Note: arbitrum does not support eip1559
      type: network === DepositNetwork.Arbitrum ? 'legacy' : 'eip1559',
    }),
    fetchBridgeDepositGasLimit(ownerAddress, network, true /* isNative */),
  ])
  const { maxFeePerGas, maxPriorityFeePerGas, gasPrice } = feePerGas
  const gasFee =
    maxFeePerGas && maxPriorityFeePerGas
      ? (maxFeePerGas + maxPriorityFeePerGas) * gasLimit
      : // Handle legacy fee estimation, i.e. for Arbitrum
      gasPrice
      ? gasPrice * gasLimit
      : BigInt(0)

  // Add 10% overhead to native gas fee
  return (gasFee * BigInt(110)) / BigInt(100)
}

export const fetchSocketMessageStatusFromTx = async (txHash: string, srcChainId: string) => {
  const queryParams = formatQueryParams({ srcChainSlug: srcChainId, srcTxHash: txHash })
  const res = await fetch(new URL(`?${queryParams}`, SOCKET_TX_API_URL), { method: 'GET' })
  return (await res.json()) as SocketStatusResponse
}

export const fetchSocketMessageStatusFromId = async (messageId: string) => {
  const queryParams = formatQueryParams({ messageId })
  const res = await fetch(new URL(`?${queryParams}`, SOCKET_MESSAGE_API_URL), { method: 'GET' })
  return (await res.json()) as SocketMessageIdStatusResponse
}

// Calculate max deposit amount given a fee quote with buffer applied
export const getDepositAmountWithFees = (
  depositAmountInput: bigint,
  balance: bigint,
  selectedToken: DepositTokenId,
  depositQuote: DepositQuote | undefined
) => {
  // Fee is applied if:
  //  - feeToken matches deposit token AND
  //    - deposit is unsponsored OR
  //    - deposit is a native deposit (i.e. not routed through Gelato)
  const feeBN =
    depositQuote && depositQuote.feeToken === selectedToken ? depositQuote.fee : BigInt(0)

  // add 2.5% buffer to fee
  const feeWithBuffer = (feeBN * BigInt(1025)) / BigInt(1000)

  // only add fees to deposit amount when fee token == selected token and deposit is NOT sponsored
  const depositAmountWithFees = depositQuote ? depositAmountInput + feeWithBuffer : undefined

  // when fee is paid in deposit token, subtract fees from balance for max deposit amount
  // ignore fee if Gelato is available + balance is enough for sponsored deposit
  const maxDepositAmount = balance

  return {
    depositAmountWithFees,
    maxDepositAmount,
  }
}
