// TODO: @earthtojake move to web/constants
export const USDC_DECIMALS = 6
export const WEI_DECIMALS = 18
export const WBTC_DECIMALS = 8
export const USDT_DECIMALS = 6

// Matching.sol Action Typehash
export const ACTION_TYPES = {
  Action: [
    { name: 'accountId', type: 'uint256' },
    { name: 'nonce', type: 'uint256' },
    { name: 'module', type: 'address' },
    { name: 'data', type: 'bytes' },
    { name: 'expiry', type: 'uint256' },
    { name: 'owner', type: 'address' },
    { name: 'signer', type: 'address' },
  ],
}

export const MAX_INT = BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
