import { getProgressColorVar, ProgressColor } from '@lyra/core/utils/progress'
import React from 'react'
import { StackProps, useTheme, YStack } from 'tamagui'

export type SpinnerProps = {
  size: number
  color?: ProgressColor
  isPaused?: boolean
} & StackProps

const Spinner = ({ size, color = 'green', isPaused, ...stackProps }: SpinnerProps) => {
  const theme = useTheme()
  const hairlineColor = theme.hairline?.get()

  const themeColor = theme[getProgressColorVar(color)]?.val?.toString() ?? color

  return (
    <YStack {...stackProps} width={size} height={size} alignItems="center" justifyContent="center">
      <YStack
        borderWidth={3}
        width={size}
        height={size}
        borderColor={isPaused ? themeColor : hairlineColor}
        borderTopColor={themeColor}
        style={{
          borderRadius: '50%',
          animation: !isPaused ? `spin 0.85s linear infinite` : undefined,
        }}
      />
    </YStack>
  )
}

Spinner.displayName = 'Spinner'

export default Spinner
