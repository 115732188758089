import {
  OrderResponseSchema,
  PrivateOrderParamsSchema,
  TriggerPrice1,
} from '@lyra/core/api/types/private.order'
import { PrivateOrderQuoteResultSchema } from '@lyra/core/api/types/private.order_quote'
import {
  PublicOrderQuoteParamsSchema,
  PublicOrderQuoteResultSchema,
} from '@lyra/core/api/types/public.order_quote'

import { InstrumentType } from './instruments'
import { MarketId } from './markets'
import { CollateralId, CurrencyId } from './tokens'

export enum TimeInForce {
  GoodTilCancelled = 'gtc',
  PostOnly = 'post_only',
  FillOrKill = 'fok',
  IoC = 'ioc',
}

export enum OrderType {
  Limit = 'limit',
  Market = 'market',
}

export enum TriggerPriceType {
  Mark = 'mark',
  Index = 'index',
}

export enum TriggerType {
  StopLoss = 'stoploss',
  TakeProfit = 'takeprofit',
}

export type OrderParams = PrivateOrderParamsSchema

export type Order = OrderResponseSchema

export type OpenOrder = Order & { order_status: 'open' | 'untriggered' }
export type ClosedOrder = Order & { order_status: 'filled' | 'cancelled' | 'expired' }

export type Orders = OrderResponseSchema[]

export type OrderParamsWithoutSignature = Omit<
  PrivateOrderParamsSchema,
  'signer' | 'signature' | 'nonce'
>

export type OrderQuoteParams = Omit<
  PublicOrderQuoteParamsSchema,
  | 'signer'
  | 'signature'
  | 'nonce'
  | 'signature_expiry_sec'
  | 'subaccount_id'
  | 'max_fee'
  | 'order_type'
  | 'time_in_force'
> & {
  // make fields required
  order_type: OrderType
  time_in_force: TimeInForce
}

export type SetOrderQuoteParams = (
  value: OrderQuoteParams | ((old: OrderQuoteParams | null) => OrderQuoteParams | null) | null
) => any

export type SetOrderQuoteParamsForInstrument = (
  value: OrderQuoteParams | ((old: OrderQuoteParams) => OrderQuoteParams | null) | null
) => any

export type Quote = PrivateOrderQuoteResultSchema
export type OrderQuote = PublicOrderQuoteResultSchema

export type TriggerPrice = TriggerPrice1

export const PERPS_LEVERAGE_STEP_SIZE = 0.01
export const DEFAULT_ORDER_EXPIRY_DAYS = 28
export const MAX_ORDER_EXPIRY_DAYS = 89

// TO DO: NEEDS VERIFICATION ON MULTIPLIER FOR COLLATERALS @mypalvikram
export const MAX_FEE_MULTIPLIER: Record<CurrencyId, number> = {
  [MarketId.BTC]: 5,
  [MarketId.ETH]: 5,
  [MarketId.SOL]: 5,
  [MarketId.DOGE]: 5000, // NOTE: DOGE has a higher fee multiplier due to min 1000 DOGE trade size
  [MarketId.AAVE]: 5,
  [MarketId.ARB]: 5,
  [MarketId.BNB]: 5,
  [MarketId.NEAR]: 5,
  [MarketId.OP]: 5,
  [MarketId.PEPE]: 5000000,
  [MarketId.SUI]: 5,
  [MarketId.TIA]: 5,
  [MarketId.WIF]: 5,
  [MarketId.WLD]: 5,
  [MarketId.LINK]: 5,
  [MarketId.XRP]: 5,
  [MarketId.AVAX]: 5,
  [MarketId.UNI]: 5,
  [MarketId.TAO]: 5,
  [MarketId.ENA]: 5,
  [CollateralId.DAI]: 5,
  [CollateralId.LBTC]: 5,
  [CollateralId.RSETH]: 5,
  [CollateralId.RSWETH]: 5,
  [CollateralId.SDAI]: 5,
  [CollateralId.SUSDE]: 5,
  [CollateralId.USDC]: 5,
  [CollateralId.USDE]: 5,
  [CollateralId.USDT]: 5,
  [CollateralId.WEETH]: 5,
  [CollateralId.WSTETH]: 5,
  [CollateralId.CBBTC]: 5,
  [CollateralId.EBTC]: 5,
}

export const MARK_PRICE_FEE_RATE_CAP = 0.125

export const FEES: Record<InstrumentType, { taker: number; maker: number }> = {
  [InstrumentType.Options]: {
    taker: 0.0004,
    maker: 0.0003,
  },
  [InstrumentType.Perps]: {
    taker: 0.0006,
    maker: 0.0001,
  },
  [InstrumentType.Spot]: {
    taker: 0.0015,
    maker: 0.0015,
  },
}
