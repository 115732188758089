import { OrderType1, TriggerType1 } from '@lyra/core/api/types/private.order'
import { InvalidReason } from '@lyra/core/api/types/private.order_quote'
import { SECONDS_IN_DAY } from '@lyra/core/constants/time'
import formatNumber from '@lyra/core/utils/formatNumber'
import formatUSD from '@lyra/core/utils/formatUSD'

import { InstrumentType, Ticker } from '../constants/instruments'
import {
  DEFAULT_ORDER_EXPIRY_DAYS,
  MARK_PRICE_FEE_RATE_CAP,
  MAX_ORDER_EXPIRY_DAYS,
  OrderQuoteParams,
  OrderType,
  TimeInForce,
  TriggerPriceType,
  TriggerType,
} from '../constants/order'
import { Position } from '../constants/position'
import { Subaccount } from '../constants/subaccount'
import {
  getBestOfferPrice,
  parseInstrumentName,
  parseOptionFromInstrumentName,
} from './instruments'
import { countDecimals, roundDownToStep } from './number'
import { getUtcNowSecs } from './time'
import { coerce } from './types'

// TODO: @earthtojake rename getBestOfferPrice
export const getDefaultLimitPrice = (ticker: Ticker, isBuy: boolean) => {
  const bidPrice = ticker.best_bid_price ? +ticker.best_bid_price : undefined
  const askPrice = ticker.best_ask_price ? +ticker.best_ask_price : undefined

  return isBuy ? askPrice : bidPrice
}

export const getOrderMinAmount = (quoteParams: OrderQuoteParams, ticker: Ticker) => {
  /**
   * if market order or FOK/IOC limit order: ticker.amount_step
   * else: ticker.minimum_amount
   */
  return quoteParams.order_type === OrderType.Market ||
    [TimeInForce.FillOrKill, TimeInForce.IoC].includes(quoteParams.time_in_force)
    ? +ticker.amount_step
    : +ticker.minimum_amount
}

export const getValidOrderAmount = (
  amount: number,
  quoteParams: OrderQuoteParams,
  ticker: Ticker
) => {
  const minAmount = getOrderMinAmount(quoteParams, ticker)
  return roundDownToStep(Math.max(amount, minAmount), +ticker.amount_step)
}

export const getOrderQuotePremiums = (
  fillPrice: number,
  amount: number,
  isBuy: boolean,
  fees?: number
) => {
  // add fees when paying premiums, subtract fees when receiving premiums
  const premiums = fillPrice * amount + Math.abs(fees ?? 0) * (isBuy ? 1 : -1)
  return premiums
}

// TODO: @earthtojake rename getMinMaxPrice
export const getDefaultMarketPrice = (ticker: Ticker, isBuy: boolean) => {
  const minPrice = +ticker.min_price
  const maxPrice = +ticker.max_price

  return isBuy ? maxPrice : minPrice
}

// TODO: @earthtojake rename getBestOrMinMaxPrice
export const getLimitPrice = (ticker: Ticker, isBuy: boolean) => {
  const bidPrice = +ticker.best_bid_price !== 0 ? +ticker.best_bid_price : +ticker.min_price
  const askPrice = +ticker.best_ask_price !== 0 ? +ticker.best_ask_price : +ticker.max_price
  return isBuy ? askPrice : bidPrice
}

export const getOrderSignatureExpirySecs = (
  instrumentName: string,
  orderType: OrderType,
  timeInForce: TimeInForce,
  _expirySecs: number
) => {
  // market, ioc and foc orders always have 1d expiry
  const expirySecs =
    orderType === OrderType.Market
      ? SECONDS_IN_DAY
      : timeInForce === TimeInForce.IoC || timeInForce === TimeInForce.FillOrKill
      ? SECONDS_IN_DAY
      : _expirySecs

  let signatureExpirySecs = getUtcNowSecs() + expirySecs

  const option = parseOptionFromInstrumentName(instrumentName)
  if (option) {
    // ensure expiry secs is less than option expiry
    const optionExpiryUtcSecs = Math.floor(option.expiry.getTime() / 1000)
    signatureExpirySecs = Math.min(signatureExpirySecs, optionExpiryUtcSecs - 1)
  }

  return signatureExpirySecs
}

export const getOrderSignatureExpirySecsFromOrderQuoteParams = (quoteParams: OrderQuoteParams) => {
  const orderType = quoteParams.order_type
  const timeInForce = quoteParams.time_in_force

  // market, ioc and foc orders always have 1d expiry
  const expirySecs =
    orderType === OrderType.Market
      ? SECONDS_IN_DAY
      : timeInForce === TimeInForce.IoC || timeInForce === TimeInForce.FillOrKill
      ? SECONDS_IN_DAY
      : getDefaultOrderExpiryDays(quoteParams) * SECONDS_IN_DAY

  let signatureExpirySecs = getUtcNowSecs() + expirySecs

  const option = parseOptionFromInstrumentName(quoteParams.instrument_name)
  if (option) {
    // ensure expiry secs is less than option expiry
    const optionExpiryUtcSecs = Math.floor(option.expiry.getTime() / 1000)
    signatureExpirySecs = Math.min(signatureExpirySecs, optionExpiryUtcSecs - 1)
  }

  return signatureExpirySecs
}

export const getOptionProjectedSettlePnl = (
  instrumentName: string,
  direction: 'buy' | 'sell',
  spotPriceAtExpiry: number,
  pricePerOption: number,
  size: number,
  liquidationPrice?: number | null
): number => {
  const instrument = parseInstrumentName(instrumentName)
  if (instrument?.type !== InstrumentType.Options) {
    return 0
  }

  const { isCall, strikePrice } = instrument

  if (direction === 'buy') {
    if (isCall) {
      // Long call
      return (
        (spotPriceAtExpiry >= strikePrice
          ? // ITM
            spotPriceAtExpiry - strikePrice - pricePerOption
          : // OTM
            pricePerOption * -1) * size
      )
    } else {
      // Long put
      return (
        (spotPriceAtExpiry <= strikePrice
          ? // ITM
            strikePrice - spotPriceAtExpiry - pricePerOption
          : // OTM
            pricePerOption * -1) * size
      )
    }
  } else {
    if (isCall) {
      return (
        (liquidationPrice && spotPriceAtExpiry >= liquidationPrice
          ? pricePerOption - spotPriceAtExpiry // Liquidation (max loss)
          : spotPriceAtExpiry <= strikePrice
          ? // OTM
            pricePerOption
          : // ITM
            pricePerOption - spotPriceAtExpiry + strikePrice) * size
      )
    } else {
      // Cash secured put
      return (
        (liquidationPrice && spotPriceAtExpiry <= liquidationPrice
          ? pricePerOption - strikePrice // Liquidation (max loss)
          : spotPriceAtExpiry <= strikePrice
          ? // ITM
            spotPriceAtExpiry - strikePrice + pricePerOption
          : // OTM
            pricePerOption) * size
      )
    }
  }
}

export const getOptionMaxProfit = (
  instrumentName: string,
  premiums: number,
  isBuy: boolean
): number => {
  const instrument = parseInstrumentName(instrumentName)
  if (instrument?.type !== InstrumentType.Options) {
    return 0
  }
  const { isCall, strikePrice } = instrument
  if (isCall && isBuy) {
    return Infinity
  } else if (isCall && !isBuy) {
    return premiums
  } else if (!isCall && isBuy) {
    return strikePrice - premiums
  } else {
    return premiums
  }
}

export const getOptionMaxLoss = (
  instrumentName: string,
  premiums: number,
  isBuy: boolean
): number => {
  const instrument = parseInstrumentName(instrumentName)
  if (instrument?.type !== InstrumentType.Options) {
    return 0
  }
  const { isCall, strikePrice } = instrument
  if (isCall && isBuy) {
    return premiums
  } else if (isCall && !isBuy) {
    return Number.MAX_VALUE
  } else if (!isCall && isBuy) {
    return premiums
  } else {
    return strikePrice - premiums
  }
}

export const getOptionBreakEvenPrice = (
  instrumentName: string,
  premiumsPerOption: number
): number => {
  const instrument = parseInstrumentName(instrumentName)
  if (instrument?.type !== InstrumentType.Options) {
    return 0
  }
  const { isCall, strikePrice } = instrument
  return isCall ? strikePrice + premiumsPerOption : strikePrice - premiumsPerOption
}

type EstTradeFeeParams = Pick<Ticker, 'instrument_name' | 'index_price'> & {
  taker_fee_rate: string
  mark_price: string
  base_fee: string
}

export const getEstTradeFee = (
  { instrument_name, index_price, taker_fee_rate, mark_price, base_fee }: EstTradeFeeParams,
  size: number
): number => {
  const parsedInstrument = parseInstrumentName(instrument_name)
  if (!parsedInstrument) {
    return 0
  }
  if (parsedInstrument.type === InstrumentType.Perps) {
    return +index_price * +taker_fee_rate * size + +base_fee
  } else {
    return (
      Math.min(+mark_price * MARK_PRICE_FEE_RATE_CAP, +index_price * +taker_fee_rate) * size +
      +base_fee
    )
  }
}

export const getInvalidReasonWarning = (invalidReason: InvalidReason): string | null => {
  switch (invalidReason) {
    case 'Account is currently under maintenance margin requirements, trading is frozen.':
      return 'Your account is under maintenance margin requirements, trading is frozen.'
    case 'Consider canceling other limit orders or using IOC, FOK, or market orders. This order is risk-reducing, but if filled with other open orders, buying power might be insufficient.':
      return 'This order is risk-reducing, but if filled with other open orders, buying power might be insufficient. Consider canceling other limit orders or using IOC, FOK, or market orders.'
    case 'Insufficient buying power, consider reducing order size or canceling other orders.':
      return 'Insufficient balance, consider reducing order size or canceling other orders.'
    case 'Insufficient buying power, only a single risk-reducing open order is allowed.':
      return 'Insufficient balance, only orders that reduce margin requirements are allowed.'
    case 'This order would cause account to fall under maintenance margin requirements.':
      return 'This order would cause your account to fall under maintenance margin requirements.'
    default:
      return null
  }
}

export const getTriggerPriceSlippageForLimitPrice = (
  isBuy: boolean,
  limitPrice: number,
  triggerPrice: number
): number => {
  // buy = limit price > trigger price
  // sell = limit price < trigger price
  if (triggerPrice === 0 || limitPrice === 0) {
    return 0
  }
  return ((isBuy ? 1 : -1) * (limitPrice - triggerPrice)) / triggerPrice
}

export const getLimitPriceForTriggerPriceSlippage = (
  isBuy: boolean,
  triggerPrice: number,
  triggerPriceSlippage: number
): number => {
  // buy = limit price > trigger price, positive slippage
  // sell = limit price < trigger price, negative slippage
  if (!triggerPrice) {
    return 0
  }
  const factor = isBuy ? 1 + triggerPriceSlippage : 1 - triggerPriceSlippage
  return triggerPrice * factor
}

export const formatOrderType = (
  orderType: OrderType1 | TriggerType1,
  abbreviate?: boolean
): string => {
  // TODO: @earthtojake show type of TP/SL order
  switch (orderType) {
    case 'limit':
      return 'Limit'
    case 'market':
      return 'Market'
    case 'takeprofit':
      return abbreviate ? 'TP' : 'Take Profit'
    case 'stoploss':
      return abbreviate ? 'SL' : 'Stop Loss'
    default:
      return 'Unknown'
  }
}

export const formatOrderPrice = (amount: number | string, tickSize: number | string) => {
  const dps = countDecimals(+tickSize)
  return formatUSD(amount, { dps, showCommas: false })
}

export const formatOrderSize = (amount: number | string, amountStep: number | string) => {
  const dps = countDecimals(+amountStep)
  return formatNumber(Math.abs(+amount), { dps, showCommas: false })
}

export function formatTickSize(num: number): string {
  const decimalPlaces = countDecimals(num)
  return num.toFixed(decimalPlaces)
}

export const getLeverageFromSize = (
  subaccount: Subaccount,
  openPosition: Position | undefined,
  tradeSize: number,
  spotPrice: number
) => {
  if (tradeSize === 0) {
    const positionSize = openPosition ? +openPosition.amount : 0
    const positionLeverage =
      openPosition && openPosition.leverage
        ? +openPosition.leverage * (positionSize >= 0 ? 1 : -1)
        : 0
    return positionLeverage
  }

  // subtract options maintenance margin
  const optionsMaintenanceMargin = subaccount.positions
    .filter((position) => position.instrument_type === 'option')
    .reduce((sum, position) => sum + +position.maintenance_margin, 0)

  // add unrealized pnl
  const perpsUnrealizedPnl = subaccount.positions
    .filter((position) => position.instrument_type === 'perp')
    .reduce((sum, position) => sum + +position.mark_value, 0)

  const perpsCollateral =
    +subaccount.collaterals_value + optionsMaintenanceMargin + perpsUnrealizedPnl

  const positionSize = openPosition ? +openPosition.amount : 0
  const postTradePositionSize = tradeSize + positionSize

  // leverage can be negative
  return perpsCollateral > 0 ? (postTradePositionSize * spotPrice) / perpsCollateral : 0
}

export const getSizeFromLeverage = (
  subaccount: Subaccount,
  openPosition: Position | undefined,
  leverage: number,
  spotPrice: number
) => {
  // subtract options maintenance margin
  const optionsMaintenanceMargin = subaccount.positions
    .filter((position) => position.instrument_type === 'option')
    .reduce((sum, position) => sum + +position.maintenance_margin, 0)

  // add unrealized pnl
  const perpsUnrealizedPnl = subaccount.positions
    .filter((position) => position.instrument_type === 'perp')
    .reduce((sum, position) => sum + +position.mark_value, 0)

  const perpsCollateral =
    +subaccount.collaterals_value + optionsMaintenanceMargin + perpsUnrealizedPnl

  const targetSizeFromLeverage = spotPrice > 0 ? (leverage * perpsCollateral) / spotPrice : 0

  const positionSize = openPosition ? +openPosition.amount : 0

  const sizeFromLeverage = targetSizeFromLeverage - positionSize // offset current position

  // size can be negative
  return sizeFromLeverage
}

export const parseOrderQuoteParams = (
  params: any,
  instrumentName?: string
): OrderQuoteParams | null => {
  if (!params || typeof params !== 'object') {
    return null
  }

  const instrument_name =
    'instrument_name' in params && typeof params.instrument_name === 'string'
      ? params.instrument_name.toUpperCase()
      : undefined
  if (!instrument_name || (instrumentName && instrumentName !== instrument_name)) {
    return null
  }

  const amount = 'amount' in params && typeof params.amount === 'string' ? params.amount : undefined
  if (!amount) {
    return null
  }

  const direction =
    'direction' in params && (params.direction === 'buy' || params.direction === 'sell')
      ? params.direction
      : undefined
  if (!direction) {
    return null
  }

  const limit_price =
    'limit_price' in params && typeof params.limit_price === 'string'
      ? params.limit_price
      : undefined
  if (!limit_price) {
    return null
  }

  const order_type = coerce(
    OrderType,
    (params as any).order_type,
    // default to limit
    OrderType.Limit
  )

  const reduce_only =
    'reduce_only' in params && typeof params.reduce_only === 'boolean'
      ? params.reduce_only
      : undefined

  const time_in_force = coerce(
    TimeInForce,
    (params as any).time_in_force,
    // default to gtc
    TimeInForce.GoodTilCancelled
  )

  const trigger_price =
    'trigger_price' in params && typeof params.trigger_price === 'string'
      ? params.trigger_price
      : undefined
  const trigger_type = coerce(TriggerType, (params as any).trigger_type)
  const trigger_price_type = coerce(TriggerPriceType, (params as any).trigger_price_type)

  const orderParams: OrderQuoteParams = {
    amount,
    direction,
    instrument_name,
    limit_price,
    order_type,
    reduce_only,
    time_in_force,
    trigger_price,
    trigger_type,
    trigger_price_type,
  }

  return orderParams
}

export const getDefaultOrderQuoteParams = (instrumentName: string): OrderQuoteParams => ({
  amount: '0',
  direction: 'buy',
  instrument_name: instrumentName,
  limit_price: '0',
  order_type: OrderType.Limit,
  time_in_force: TimeInForce.GoodTilCancelled,
})

export const getDefaultOrderExpiryDays = (quoteParams: OrderQuoteParams) => {
  const isTriggerOrder = !!quoteParams.trigger_type
  const isMarketOrder = quoteParams.order_type === OrderType.Market
  const timeInForce = quoteParams.time_in_force
  if (isTriggerOrder) {
    return MAX_ORDER_EXPIRY_DAYS // 89 days for trigger orders
  } else if (
    isMarketOrder ||
    timeInForce === TimeInForce.IoC ||
    timeInForce === TimeInForce.FillOrKill
  ) {
    return 1 // 1 day for market, ioc, fok orders
  } else {
    return DEFAULT_ORDER_EXPIRY_DAYS // 28 days by default
  }
}

export const getSignatureExpirySecsForExpiryDays = (instrumentName: string, expiryDays: number) => {
  const expirySecs = expiryDays * SECONDS_IN_DAY

  const signatureExpirySecs = getUtcNowSecs() + expirySecs

  const option = parseOptionFromInstrumentName(instrumentName)
  if (option) {
    // ensure expiry secs is less than option expiry
    const optionExpiryUtcSecs = Math.floor(option.expiry.getTime() / 1000)
    return Math.min(signatureExpirySecs, optionExpiryUtcSecs - 1)
  } else {
    return signatureExpirySecs
  }
}

export const getRoundedQuoteSize = (size: number, limitPrice: number, amountStep: number) => {
  const baseSize = size / limitPrice
  return roundDownToStep(baseSize * limitPrice, amountStep)
}

export const getIsLimitPriceOutOfBounds = (
  limitPrice: number,
  quoteParams: OrderQuoteParams,
  ticker: Ticker
) => {
  // trigger orders are NEVER out of bounds
  if (!!quoteParams.trigger_type) {
    return false
  }

  const tickSize = +ticker.tick_size
  const minPrice = roundDownToStep(+ticker.min_price, tickSize)
  const maxPrice = roundDownToStep(+ticker.max_price, tickSize)
  const isBuy = quoteParams.direction === 'buy'

  if (isBuy && limitPrice > maxPrice) {
    // exceeded max price, default to BBO or min/max
    return true
  }

  if (!isBuy && limitPrice < minPrice) {
    // exceeded min price, default to BBO or min/max
    return true
  }

  return false
}

export const getIsValidLimitPrice = (
  limitPrice: number,
  quoteParams: OrderQuoteParams,
  ticker: Ticker
) => {
  return !!limitPrice && !getIsLimitPriceOutOfBounds(limitPrice, quoteParams, ticker)
}

export const getAutoLimitPrice = (
  quoteParams: Pick<OrderQuoteParams, 'order_type' | 'direction'>,
  ticker: Ticker
) => {
  const tickSize = +ticker.tick_size
  const minPrice = roundDownToStep(+ticker.min_price, tickSize)
  const maxPrice = roundDownToStep(+ticker.max_price, tickSize)
  const isBuy = quoteParams.direction === 'buy'
  // IMPORTANT!! if there is no BBO, default to $0 -- force user to input limit price
  const bestOfferPrice = roundDownToStep(getBestOfferPrice(ticker, isBuy) ?? 0, tickSize)
  return quoteParams.order_type === OrderType.Market
    ? isBuy
      ? maxPrice
      : minPrice
    : bestOfferPrice
}

export const getValidLimitPrice = (
  rawLimitPrice: number,
  quoteParams: OrderQuoteParams,
  ticker: Ticker
) => {
  const tickSize = +ticker.tick_size
  const roundedRawLimitPrice = roundDownToStep(rawLimitPrice, tickSize)

  const defaultLimitPrice = getAutoLimitPrice(quoteParams, ticker)

  if (!roundedRawLimitPrice) {
    // no limit price defined, use default limit price
    return defaultLimitPrice
  }

  if (getIsLimitPriceOutOfBounds(rawLimitPrice, quoteParams, ticker)) {
    // exceeded min or max price, use default limit price
    return defaultLimitPrice
  }

  return roundedRawLimitPrice
}
