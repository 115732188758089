export type BodyTextSize = 'sm' | 'md' | 'lg' | 'editorial'

export type HeadingSize = 'h4' | 'h3' | 'h2' | 'h1' | 'display'

export type VideoTextSize = 'sm' | 'md' | 'lg'

export type TextColor =
  | 'primary'
  | 'secondary'
  | 'red'
  | 'amber'
  | 'green'
  | 'blue'
  | 'inverted'
  | 'inherit'

export const getTextColorVar = (color: TextColor): string => {
  switch (color) {
    case 'primary':
      return '$primaryText'
    case 'secondary':
      return '$secondaryText'
    case 'red':
      return '$redText'
    case 'amber':
      return '$amberText'
    case 'green':
      return '$greenText'
    case 'blue':
      return '$blueText'
    case 'inverted':
      return '$invertedText'
    case 'inherit':
      return 'inherit'
  }
}
