import Spinner from '@lyra/core/components/Spinner'
import useHover from '@lyra/core/hooks/useHover'
import usePress from '@lyra/core/hooks/usePress'
import { TextColor } from '@lyra/core/utils/text'
import { GestureReponderEvent } from '@tamagui/web'
import React, { forwardRef, useCallback, useState } from 'react'
import { StackProps, XStack } from 'tamagui'

import Icon, { SVGIcon } from '../Icon'
import VideoText, { VideoTextProps } from '../VideoText'
import { Button as StyledButton } from './styles'

export type ButtonSize = 'sm' | 'md' | 'lg'

export type ButtonColor = 'primary' | 'green' | 'red' | 'amber' | 'blue' | 'cta'

export type ButtonProps = {
  label?: React.ReactNode
  icon?: SVGIcon | null
  iconSize?: number
  leftIcon?: SVGIcon | null
  rightContent?: React.ReactNode
  leftContent?: React.ReactNode
  color?: ButtonColor
  isSolid?: boolean
  isTransparent?: boolean
  isSelected?: boolean
  isOutlined?: boolean
  isDisabled?: boolean
  isLoading?: boolean
  size?: ButtonSize
  href?: string
  target?: string
  textAlign?: VideoTextProps['textAlign']
  textTransform?: VideoTextProps['textTransform']
  isCta?: boolean
  onPress?: ((e: GestureReponderEvent) => any | Promise<any>) | null
} & Omit<StackProps, 'height' | 'maxHeight' | 'minHeight'>

export type CtaVariant = 'default' | 'solid' | 'outlined' | null

function getCtaVariant(
  color: string,
  isSolid: boolean | undefined,
  isOutlined: boolean | undefined,
  isDisabled: boolean | undefined
): CtaVariant {
  if (color !== 'cta' || isDisabled) {
    return null
  }
  if (isSolid) {
    return 'solid'
  }
  if (isOutlined) {
    return 'outlined'
  }
  return 'default'
}

export const getButtonMinHeight = (size: ButtonSize): string => {
  switch (size) {
    case 'sm':
      return '$4'
    case 'md':
      return '$6'
    case 'lg':
      return '$7'
  }
}

export const getButtonPaddingHorizontal = (size: ButtonSize): string => {
  switch (size) {
    case 'sm':
      return '$1'
    case 'md':
      return '$1.5'
    case 'lg':
      return '$2'
  }
}

export const getButtonPaddingVertical = (size: ButtonSize): string => {
  switch (size) {
    case 'sm':
      return '$0.5'
    case 'md':
      return '$1'
    case 'lg':
      return '$1.5'
  }
}

export const getDefaultButtonIconSize = (size: ButtonSize): number => {
  switch (size) {
    case 'sm':
      return 14
    case 'md':
      return 16
    case 'lg':
      return 18
  }
}

export const getButtonHorizontalGap = (size: ButtonSize): string => {
  return size === 'lg' ? '$1.5' : size === 'md' ? '$1' : '$0.5'
}

function isPromise(func: any): func is (...args: any[]) => Promise<any> {
  return !!func && func?.constructor?.name === 'AsyncFunction'
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      color: colorInput = 'primary',
      isSolid: isSolidInput = false,
      isTransparent: isTransparentInput = false,
      isDisabled: isDisabledInput = false,
      isLoading: isLoadingInput = false,
      icon: iconInput,
      leftIcon: leftIconInput,
      iconSize,
      label,
      rightContent,
      leftContent,
      size = 'md',
      isSelected,
      isOutlined,
      href,
      target,
      textAlign = 'center',
      textTransform,
      isCta,
      onPress,
      onHoverIn,
      onHoverOut,
      onPressIn,
      onPressOut,
      ...styleProps
    }: ButtonProps,
    ref
  ) => {
    const {
      isHovering,
      handleHoverIn: _handleHoverIn,
      handleHoverOut: _handleHoverOut,
    } = useHover()
    const {
      isPressing,
      handlePressIn: _handlePressIn,
      handlePressOut: _handlePressOut,
    } = usePress()

    const color = isCta ? 'cta' : colorInput
    const isSolid = isCta ? true : isSolidInput

    // isSolid takes precendent over isTransparent
    const isTransparent = isTransparentInput && color === 'primary' && !isSolidInput

    const [isPressLoading, setIsPressLoading] = useState(false)

    const handleOnPress = useCallback(
      async (e: GestureReponderEvent) => {
        if (onPress) {
          if (isPromise(onPress)) {
            let isPressComplete = false
            // Set isPressLoading delay to skip for sync onPress functions
            setTimeout(() => {
              if (!isPressComplete) {
                setIsPressLoading(true)
              }
            }, 20)
            try {
              await onPress(e)
            } catch (error) {
              setIsPressLoading(false)
              isPressComplete = true
              throw error
            } finally {
              setIsPressLoading(false)
              isPressComplete = true
            }
          } else {
            onPress(e)
          }
        }
      },
      [onPress]
    )

    const isLoading = isPressLoading || isLoadingInput
    const isDisabled = isDisabledInput || isLoading

    const ctaVariant = getCtaVariant(color, isSolid, isOutlined, isDisabled)

    const textColor: TextColor | 'cta' = isDisabled
      ? 'secondary'
      : isSolid && color === 'primary'
      ? 'inverted'
      : isPressing || isSelected
      ? 'inverted'
      : isHovering
      ? isTransparent
        ? 'primary'
        : isSolid
        ? 'inverted'
        : color
      : isTransparent
      ? 'secondary'
      : isSolid
      ? 'inverted'
      : color

    const icon = isLoading ? (
      // isLoading is always disabled state
      <Spinner size={iconSize ? iconSize : getDefaultButtonIconSize(size)} color="primary" />
    ) : iconInput ? (
      <Icon size={iconSize ?? getDefaultButtonIconSize(size)} icon={iconInput} color={textColor} />
    ) : null

    const leftIcon = leftIconInput ? (
      <Icon
        size={iconSize ?? getDefaultButtonIconSize(size)}
        icon={leftIconInput}
        color={textColor}
      />
    ) : null

    const isIconOnly = !!iconInput && !label

    const handleHoverIn: React.MouseEventHandler<HTMLDivElement> = useCallback(
      (e) => {
        _handleHoverIn()
        if (onHoverIn) {
          onHoverIn(e)
        }
      },
      [_handleHoverIn, onHoverIn]
    )

    const handleHoverOut: React.MouseEventHandler<HTMLDivElement> = useCallback(
      (e) => {
        _handleHoverOut()
        if (onHoverOut) {
          onHoverOut(e)
        }
      },
      [_handleHoverOut, onHoverOut]
    )

    const handlePressIn = useCallback(
      (e: GestureReponderEvent) => {
        _handlePressIn()
        if (onPressIn) {
          onPressIn(e)
        }
      },
      [_handlePressIn, onPressIn]
    )

    const handlePressOut = useCallback(
      (e: GestureReponderEvent) => {
        _handlePressOut()
        if (onPressOut) {
          onPressOut(e)
        }
      },
      [_handlePressOut, onPressOut]
    )

    return (
      <StyledButton
        ref={ref}
        size={size}
        isIconOnly={isIconOnly}
        theme={color}
        isTransparent={isTransparent}
        isSolid={isSolidInput || isSelected}
        isOutlined={isOutlined}
        isDisabled={isDisabled}
        isCtaDefault={ctaVariant === 'default'}
        isCtaSolid={ctaVariant === 'solid'}
        isCtaOutlined={ctaVariant === 'outlined'}
        tag={href ? 'a' : 'button'}
        target={target}
        href={href ? href : undefined}
        onPress={handleOnPress}
        onHoverIn={handleHoverIn}
        onHoverOut={handleHoverOut}
        onPressIn={handlePressIn}
        onPressOut={handlePressOut}
        {...styleProps}
        role="checkbox"
        style={
          // Tamagui doesn't support borderImage so we need to add it here
          ctaVariant === 'outlined'
            ? {
                borderStyle: 'solid',
                borderImageSource: 'linear-gradient(120deg, #FCB124, #F3697B)',
                borderImageSlice: 1,
              }
            : undefined
        }
      >
        {isIconOnly ? (
          <XStack alignItems="center" justifyContent="center">
            {leftContent}
            {icon}
            {rightContent}
          </XStack>
        ) : (
          <XStack
            width="100%"
            gap={getButtonHorizontalGap(size)}
            alignItems="center"
            justifyContent="center"
          >
            {leftContent}
            {leftIcon}
            {label ? (
              <XStack
                flexGrow={textAlign !== 'center' || !textAlign ? 1 : 0}
                alignItems="center"
                justifyContent={
                  textAlign === 'left'
                    ? 'flex-start'
                    : textAlign === 'right'
                    ? 'flex-end'
                    : 'center'
                }
              >
                <VideoText
                  isBold
                  size={size}
                  color={textColor}
                  textAlign={textAlign}
                  textTransform={textTransform}
                >
                  {label}
                </VideoText>
              </XStack>
            ) : null}
            {icon}
            {rightContent}
          </XStack>
        )}
      </StyledButton>
    )
  }
)

Button.displayName = 'Button'

export default Button
