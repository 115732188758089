import type { FormatNumberOptions } from './formatNumber'
import formatNumber from './formatNumber'

type FormatUSDOptions = FormatNumberOptions

export default function formatUSD(price: number | string, options?: FormatUSDOptions): string {
  const numberPrice = typeof price === 'string' ? parseFloat(price) : price
  if (typeof numberPrice === 'number' && isNaN(numberPrice)) {
    return ''
  }
  const numStr = formatNumber(numberPrice, { ...options, dps: options?.dps ?? 2 })
  const isSigned = numStr.startsWith('-') || numStr.startsWith('+')
  if (isSigned) {
    return `${numStr.slice(0, 1)}$${numStr.slice(1)}`
  } else {
    return `$${numStr}`
  }
}
