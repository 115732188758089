import { useCallback, useState } from 'react'

const useHover = () => {
  const [isHovering, setIsHovering] = useState(false)

  const handleHoverIn = useCallback(() => setIsHovering(true), [])
  const handleHoverOut = useCallback(() => setIsHovering(false), [])

  return {
    isHovering,
    handleHoverIn,
    handleHoverOut,
  }
}

export default useHover
