import { CurrencyResponseSchema } from '@lyra/core/api/types/public.get_all_currencies'

import { lyraContractAddresses } from '../constants/contracts'
import { InstrumentType } from '../constants/instruments'
import { EMPTY_MARKETS, Market, MarketId, marketsConfig } from '../constants/markets'
import { Subaccount } from '../constants/subaccount'
import { collateralConfig, CollateralId, CurrencyId } from '../constants/tokens'
import { parseOptionFromInstrumentName, parsePerpFromInstrumentName } from './instruments'
import { formatTokenSymbol } from './tokens'
import { coerce } from './types'

export const getMarketId = (
  marketish: string | { base_currency: string }
): MarketId | undefined => {
  let tidyMarket = typeof marketish === 'string' ? marketish : marketish['base_currency']
  switch (tidyMarket.toLowerCase()) {
    case 'weth':
      tidyMarket = 'eth'
      break
    case 'btc':
    case 'wbtc':
    case 'lbtc':
      tidyMarket = 'btc'
      break
  }
  return coerce(MarketId, tidyMarket.toUpperCase())
}

export const formatMarketName = (marketId: MarketId): string => {
  return marketsConfig[marketId].name
}

export const getCollateralId = (collateralish: string): CollateralId | undefined => {
  return coerce(CollateralId, collateralish.toUpperCase())
}

export const formatCollateralName = (collateralId: CollateralId): string => {
  return collateralConfig[collateralId].symbol
}

export function getActivePerpsMarketIds(): MarketId[] {
  return Object.entries(marketsConfig)
    .filter(([_, marketInfo]) => marketInfo.perps?.isActive)
    .map(([marketId, _]) => marketId as MarketId)
}

export function getListedPerpsMarketIds(): MarketId[] {
  return getActivePerpsMarketIds().filter((marketId) => !marketsConfig[marketId].perps?.isUnlisted)
}

export function getActiveOptionsMarketIds(): MarketId[] {
  return Object.entries(marketsConfig)
    .filter(([_, marketInfo]) => marketInfo.options?.isActive)
    .map(([marketId, _]) => marketId as MarketId)
}

export function getListedOptionsMarketIds(): MarketId[] {
  return getActiveOptionsMarketIds().filter(
    (marketId) => !marketsConfig[marketId].options?.isUnlisted
  )
}

// Active => tradeable
export function getActiveSpotCollateralIds(): CollateralId[] {
  return Object.entries(collateralConfig)
    .filter(([_, marketInfo]) => marketInfo.spot?.isActive)
    .map(([symbol, _]) => symbol as CollateralId)
}

// Active AND Listed
export function getListedSpotCollateralIds(): CollateralId[] {
  return getActiveSpotCollateralIds().filter(
    (collatId) => !collateralConfig[collatId].spot?.isUnlisted
  )
}

export function getActivePortfolioManagerMarketIds(): MarketId[] {
  return Object.values(MarketId).filter(
    (marketId) => !!lyraContractAddresses.portfolioManager[marketId]
  )
}

export const hasDogePositions = (subaccounts: Subaccount[]): boolean => {
  return subaccounts.some((subaccount) =>
    subaccount.positions.some((p) => {
      const parsedInstrument =
        p.instrument_type === InstrumentType.Options
          ? parseOptionFromInstrumentName(p.instrument_name)
          : parsePerpFromInstrumentName(p.instrument_name)
      if (!parsedInstrument) {
        return false
      }
      return parsedInstrument.marketId === MarketId.DOGE
    })
  )
}

const getPriceData = (currency: CurrencyResponseSchema) => {
  const spotPrice = +currency.spot_price
  const spotPrice24hAgo = +currency.spot_price_24h
  const spotPrice24hChange = spotPrice - spotPrice24hAgo
  const spotPrice24hPctChange =
    spotPrice24hAgo !== 0 ? (spotPrice - spotPrice24hAgo) / spotPrice24hAgo : 0

  return {
    spotPrice,
    spotPrice24hAgo,
    spotPrice24hChange,
    spotPrice24hPctChange,
  }
}

export const getMarketsFromCurrencies = (
  initialCurrencies: CurrencyResponseSchema[]
): Record<MarketId, Market> => {
  return initialCurrencies.reduce((dict, currency) => {
    const priceData = getPriceData(currency)

    const marketId = getMarketId(currency.currency)
    if (!marketId) {
      return dict
    }

    return {
      ...dict,
      [marketId]: {
        ...marketsConfig[marketId],
        ...priceData,
      },
    }
  }, EMPTY_MARKETS)
}

export const formatMarketSymbol = (
  currencyId: CurrencyId,
  instrumentType: InstrumentType
): string => {
  switch (instrumentType) {
    case InstrumentType.Options:
      return `${currencyId} Options`
    case InstrumentType.Perps:
      return `${currencyId} Perps`
    case InstrumentType.Spot:
      const collateralId = coerce(CollateralId, currencyId)
      return `${collateralId ? formatTokenSymbol(collateralId) : currencyId.toUpperCase()}-USDC`
  }
}
