import { OrderQuoteParams } from './order'
import { SendRfqParams } from './rfqs'

export enum QueryParam {
  PositionTab = 'ptab',
  LimitPrice = 'limit',
  Size = 'size',
  SelectedExpiry = 'expiry',
  OrderType = 'order_type',
  RfqId = 'rfq_id',
  SubaccountId = 'subaccount_id',
  HistoryTab = 'htab',
  OrderParams = 'order',
}

export enum QueryPositionTab {
  Positions = 'positions',
  Greeks = 'greeks',
  Orders = 'orders',
  Balances = 'balances',
  Quotes = 'quotes',
}

export type OrderQueryParam = SendRfqParams | OrderQuoteParams

export enum QuerySpotOrderType {
  Limit = 'limit',
  Rfq = 'rfq',
}
