import { useCallback, useEffect, useState } from 'react'

const usePress = () => {
  const [isPressing, setIsPressing] = useState(false)

  const cancelTouches = useCallback(() => setIsPressing(false), [])

  const handlePressIn = useCallback(() => {
    setIsPressing(true)
    addEventListener('mouseup', cancelTouches)
  }, [cancelTouches])

  const handlePressOut = useCallback(() => {
    setIsPressing(false)
    removeEventListener('mouseup', cancelTouches)
  }, [cancelTouches])

  useEffect(() => {
    return () => {
      removeEventListener('mouseup', cancelTouches)
    }
  }, [cancelTouches])

  return {
    isPressing,
    handlePressIn,
    handlePressOut,
  }
}

export default usePress
