// UNSAFE

import { bigNumberToString } from './bigNumberToString'

// this function loses precision for large bigints
export function bigNumberToNumberUNSAFE(number: bigint | string, decimals: number): number {
  try {
    return Number(bigNumberToString(number, decimals))
  } catch (error) {
    console.warn('failed to convert bigint to number:', number.toString(), decimals)
    return 0
  }
}
