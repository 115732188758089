import { Chain } from 'viem'

import {
  arbitrumChain,
  baseChain,
  DepositNetwork,
  mainnetChain,
  optimismChain,
} from '../constants/chains'
import { isMainnet } from '../constants/env'

export const formatDepositNetworkName = (network: DepositNetwork): string => {
  switch (network) {
    case DepositNetwork.Ethereum:
      return isMainnet ? 'Ethereum' : 'Sepolia'
    case DepositNetwork.Optimism:
      return isMainnet ? 'Optimism' : 'Optimism Sepolia'
    case DepositNetwork.Arbitrum:
      return isMainnet ? 'Arbitrum' : 'Arbitrum Sepolia'
    case DepositNetwork.Base:
      return isMainnet ? 'Base' : 'Base Sepolia'
  }
}

export const getChainForDepositNetwork = (network: DepositNetwork): Chain => {
  switch (network) {
    case DepositNetwork.Ethereum:
      return mainnetChain
    case DepositNetwork.Optimism:
      return optimismChain
    case DepositNetwork.Arbitrum:
      return arbitrumChain
    case DepositNetwork.Base:
      return baseChain
  }
}

export const getDepositNetworkForChainId = (chainId: number): DepositNetwork | undefined => {
  switch (chainId) {
    case optimismChain.id:
      return DepositNetwork.Optimism
    case mainnetChain.id:
      return DepositNetwork.Ethereum
    case arbitrumChain.id:
      return DepositNetwork.Arbitrum
    case baseChain.id:
      return DepositNetwork.Base
    default:
      return undefined
  }
}
