import NewMainnetAddresses from './prod_lyra_addresses.json'
import OldMainnetAddresses from './prod_lyra-old_addresses.json'
import {
  MAINNET_CHAIN_ID,
  SocketDepositContractAddresses,
  SocketWithdrawContractAddresses,
} from './shared'

// Alternatively could explicitly list the chainIds to avoid casting as never/SocketDepositContractAddresses

const chainIds = Object.keys(NewMainnetAddresses).concat(...Object.keys(OldMainnetAddresses))

const depositContractAddresses: SocketDepositContractAddresses = {}

for (const chainId of chainIds) {
  const oldAddrs = (OldMainnetAddresses as any)[chainId] ?? {}
  const newAddrs = (NewMainnetAddresses as any)[chainId] ?? {}

  if (Object.keys(oldAddrs).some((tokenId) => Object.keys(newAddrs).includes(tokenId))) {
    throw new Error(`Token IDs must be unique across old and new addresses for chainId ${chainId}`)
  }

  for (const tokenId of Object.keys(oldAddrs)) {
    oldAddrs[tokenId].isNewBridge = false
  }
  for (const tokenId of Object.keys(newAddrs)) {
    newAddrs[tokenId].isNewBridge = true
  }
  depositContractAddresses[chainId] = {
    ...oldAddrs,
    ...newAddrs,
  }
}

const withdrawContractAddresses: SocketWithdrawContractAddresses = {}

for (const tokenId of Object.keys(NewMainnetAddresses[MAINNET_CHAIN_ID])) {
  const addrs = (NewMainnetAddresses as any)[MAINNET_CHAIN_ID][tokenId]
  withdrawContractAddresses[tokenId] = {
    ...addrs,
    isNewBridge: true,
  }
}

for (const tokenId of Object.keys(OldMainnetAddresses[MAINNET_CHAIN_ID])) {
  const addrs = (OldMainnetAddresses as any)[MAINNET_CHAIN_ID][tokenId]
  withdrawContractAddresses[tokenId] = {
    ...addrs,
    isNewBridge: false,
  }
}

export const MAINNET_DEPOSIT_CONTRACT_ADDRESSES = depositContractAddresses
export const MAINNET_WITHDRAW_CONTRACT_ADDRESSES = withdrawContractAddresses
