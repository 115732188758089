import { CollateralId } from './tokens'

export enum MarketId {
  ETH = 'ETH',
  BTC = 'BTC',
  SOL = 'SOL',
  DOGE = 'DOGE',
  AAVE = 'AAVE',
  ARB = 'ARB',
  BNB = 'BNB',
  NEAR = 'NEAR',
  OP = 'OP',
  PEPE = 'PEPE',
  SUI = 'SUI',
  TIA = 'TIA',
  WIF = 'WIF',
  WLD = 'WLD',
  LINK = 'LINK',
  XRP = 'XRP',
  AVAX = 'AVAX',
  UNI = 'UNI',
  TAO = 'TAO',
  ENA = 'ENA',
}

export type Market = MarketData & MarketConfig

export type MarketData = {
  marketId: MarketId
  spotPrice: number
  spotPrice24hAgo: number
  spotPrice24hChange: number
  spotPrice24hPctChange: number
}

export type MarketConfig = {
  marketId: MarketId
  name: string
  collateral?: CollateralId
  perps?: {
    isActive: boolean
    isUnlisted?: boolean
  }
  options?: {
    isActive: boolean
    isUnlisted?: boolean
  }
}

export const marketsConfig: Record<MarketId, MarketConfig> = {
  [MarketId.ETH]: {
    marketId: MarketId.ETH,
    name: 'Ethereum',
    collateral: CollateralId.ETH,
    perps: {
      isActive: true,
    },
    options: {
      isActive: true,
    },
  },
  [MarketId.BTC]: {
    marketId: MarketId.BTC,
    name: 'Bitcoin',
    collateral: CollateralId.BTC,
    perps: {
      isActive: true,
    },
    options: {
      isActive: true,
    },
  },
  [MarketId.SOL]: {
    marketId: MarketId.SOL,
    name: 'Solana',
    perps: {
      isActive: true,
    },
  },
  [MarketId.DOGE]: {
    marketId: MarketId.DOGE,
    name: 'Dogecoin',
    perps: {
      isActive: true,
    },
  },
  [MarketId.AAVE]: {
    marketId: MarketId.AAVE,
    name: 'Aave',
    perps: {
      isActive: true,
    },
  },
  [MarketId.ARB]: {
    marketId: MarketId.ARB,
    name: 'Arbitrum',
    perps: {
      isActive: true,
    },
  },
  [MarketId.BNB]: {
    marketId: MarketId.BNB,
    name: 'BNB',
    perps: {
      isActive: true,
    },
  },
  [MarketId.NEAR]: {
    marketId: MarketId.NEAR,
    name: 'Near Protocol',
    perps: {
      isActive: true,
    },
  },
  [MarketId.OP]: {
    marketId: MarketId.OP,
    name: 'Optimism',
    perps: {
      isActive: true,
    },
  },
  [MarketId.PEPE]: {
    marketId: MarketId.PEPE,
    name: 'Pepe',
    perps: {
      isActive: true,
    },
  },
  [MarketId.SUI]: {
    marketId: MarketId.SUI,
    name: 'Sui',
    perps: {
      isActive: true,
    },
  },
  [MarketId.TIA]: {
    marketId: MarketId.TIA,
    name: 'Celestia',
    perps: {
      isActive: true,
    },
  },
  [MarketId.WIF]: {
    marketId: MarketId.WIF,
    name: 'dogwifhat',
    perps: {
      isActive: true,
    },
  },
  [MarketId.WLD]: {
    marketId: MarketId.WLD,
    name: 'Worldcoin',
    perps: {
      isActive: true,
    },
  },
  [MarketId.LINK]: {
    marketId: MarketId.LINK,
    name: 'Chainlink',
    perps: {
      isActive: true,
    },
  },
  [MarketId.XRP]: {
    marketId: MarketId.XRP,
    name: 'Ripple',
    perps: {
      isActive: true,
    },
  },
  [MarketId.AVAX]: {
    marketId: MarketId.AVAX,
    name: 'Avalanche',
    perps: {
      isActive: true,
    },
  },
  [MarketId.UNI]: {
    marketId: MarketId.UNI,
    name: 'Uniswap',
    perps: {
      isActive: true,
    },
  },
  [MarketId.TAO]: {
    marketId: MarketId.TAO,
    name: 'Bittensor',
    perps: {
      isActive: true,
    },
  },
  [MarketId.ENA]: {
    marketId: MarketId.ENA,
    name: 'Ethena',
    perps: {
      isActive: true,
    },
  },
}

export const EMPTY_MARKETS: Record<MarketId, Market> = Object.values(MarketId).reduce(
  (acc, marketId) => {
    const marketData: Market = {
      ...marketsConfig[marketId],
      marketId,
      spotPrice: 0,
      spotPrice24hAgo: 0,
      spotPrice24hChange: 0,
      spotPrice24hPctChange: 0,
    }
    acc[marketId] = marketData
    return acc
  },
  {} as Record<MarketId, Market>
)

export const DEFAULT_MARKET_ID = MarketId.ETH

export const ONLY_SHOW_EXPIRIES: Record<MarketId, string[]> = Object.values(MarketId).reduce(
  (acc, market) => {
    acc[market] = []
    return acc
  },
  {} as Record<MarketId, string[]>
)
