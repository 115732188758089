import { parseUnits } from 'viem'

export default function toBigNumber(value: number | string, decimals: number): bigint {
  if (typeof value === 'number' && Math.abs(value) < 1e-6 && value !== 0) {
    const exponent = +value.toString().split('e')[1]
    if (exponent) {
      return parseUnits(
        (value * 10 ** Math.abs(exponent)).toString(),
        decimals - Math.abs(exponent)
      )
    }
  } else if (typeof value === 'string' && value.toLowerCase().includes('e')) {
    const valueLc = value.toLowerCase()
    const exponent = +valueLc.split('e')[1]
    const val = valueLc.split('e')[0]
    const newExponent = Math.abs(decimals - Math.abs(exponent))
    if (exponent) {
      return parseUnits(val, newExponent)
    }
  }
  return parseUnits(value.toString(), decimals)
}
